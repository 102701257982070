//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { Box, Button, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';

//Internal
import { CardFilter } from '../../interfaces';
import { FilterProps } from '../../../ManageCards/interfaces';
import FilterValues from './FilterValues';

const Filter = (props: FilterProps): ReactElement => {
	const { filter, filterIndex, onFilterChange, onDelete } = props;

	const onFilterColumnChange = (newColumnName: string): void => {
		const newFilter: CardFilter = { ...filter, column: newColumnName };
		onFilterChange(newFilter, filterIndex);
	};

	const onFilterValuesChange = (newValues: string[]): void => {
		const newFilter: CardFilter = { ...filter, values: newValues };
		onFilterChange(newFilter, filterIndex);
	};

	const handleDeleteButtonClick = (): void => {
		onDelete(filterIndex);
	};

	return (
		<FormControl sx={{ width: '100%' }}>
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Typography sx={{ fontSize: '12px' }}>Filter {filterIndex + 1}</Typography>
				<Button onClick={handleDeleteButtonClick} sx={{ 'text-align': 'right', display: 'block' }}>
					X
				</Button>
			</Box>
			<Box sx={{ display: 'flex' }}>
				<TextField
					sx={{ flexBasis: '80%' }}
					autoFocus
					value={filter.column}
					placeholder="Field Name"
					onChange={(e) => onFilterColumnChange(e.target.value)}
				/>
				<Select sx={{ flexBasis: '20%' }} label="Operator" value={filter.operator} disabled={true}>
					<MenuItem value={filter.operator}>{filter.operator}</MenuItem>
				</Select>
			</Box>

			<FilterValues onChange={onFilterValuesChange} initValues={filter.values} />
		</FormControl>
	);
};

export default Filter;
