//Node Modules
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

//Material-UI
import { Box } from '@mui/material';
//Internal
import { userSlice, UserState } from '../../../store/userReducer';
import { USERS_ROOT_URL } from '../../Shared/constants/routes';
import { useFetch } from '../../Shared/hooks/useFetch';
import { Loading } from '../../Shared/components';
import { StoreState } from '../../../store/store';
import { useAuth0 } from '@auth0/auth0-react';
import { dashboardSlice } from '../../../store/dashboardReducer';
import { logoutConfig } from '../../../utils/auth0Utils';

//This exists as callback URL for AUth0 and redirects to root
export const Login = (): ReactElement => {
	const userState = useSelector((state: StoreState) => state.user);
	const dispatch = useDispatch();
	const { user, isAuthenticated, isLoading, logout } = useAuth0();

	const userData = useFetch<UserState>(`${USERS_ROOT_URL}/login`);

	useEffect(() => {
		if (userData.success && !userState.id) {
			dispatch(
				userSlice.actions.update(
					userData.success ? { ...userData.data, picture: user?.picture } : userSlice.getInitialState(),
				),
			);

			const initDashboardData = dashboardSlice.getInitialState();

			dispatch(
				dashboardSlice.actions.update(
					userData.success
						? { ...initDashboardData, selectedDashboard: userData.data?.defaultDashboardId }
						: dashboardSlice.getInitialState(),
				),
			);
		} else if (!userData.loading && !userData.success) {
			logout(logoutConfig).then();
		}
		// eslint-disable-next-line
	}, [userData.success]);

	return (
		<div>
			{!isLoading && isAuthenticated && userState.id ? (
				<Navigate to={'/'} />
			) : (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: window.innerWidth,
						height: window.innerHeight,
						background: '#264a5d',
					}}
				>
					<Loading color="info" />
				</Box>
			)}
		</div>
	);
};
