//Node Modules
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userSlice } from '../../../store/userReducer';
import { logoutConfig } from '../../../utils/auth0Utils';

export const useInactivityTimer = (startTime: number): number => {
	const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
	const [timer, setTimer] = useState<number>(startTime);
	const { logout } = useAuth0();
	const dispatch = useDispatch();

	useEffect(() => {
		const myInterval = setInterval(() => {
			if (timer > 0) {
				setTimer(timer - 1);
			} else {
				dispatch(userSlice.actions.logout());
				logout(logoutConfig).then();
			}
		}, 1000);

		const resetTimeout = () => {
			setTimer(startTime);
		};

		for (let i in events) {
			window.addEventListener(events[i], resetTimeout);
		}

		return () => {
			clearInterval(myInterval);

			for (let i in events) {
				window.removeEventListener(events[i], resetTimeout);
			}
		};
	});

	return timer;
};
