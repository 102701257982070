export const mainBorder: string = '1px solid rgb(240, 240, 240)';

export const mainColor: string = '#262626';

export const selectedColor: string = '#1677FF';

export const grayColor: string = 'rgb(140, 140, 140)';

export const sectionTitle = {
	fontSize: '0.75rem',
	fontWeight: '500',
	lineHeight: '1.66',
	fontFamily: '"Public Sans", sans-serif',
	color: grayColor,
};

export const iconWidth: string = '1.25rem';

export const itemPaddingLeft: string = '0.5rem';
