import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Loading } from '../../Shared/components';
import { useFetch } from '../../Shared/hooks/useFetch';
import { USER_LIST_URL } from '../../Shared/constants/routes';

interface IOption {
	id: string;
	label: string
}

export const SelectConnectedUsers = (props: { selected: string[],  onChange: any }): React.ReactElement => {
	const { selected, onChange } = props;
	const [options, setOptions] = useState<IOption[]>([])


	const userListRequest = useFetch<{id: string, firstName: string, lastName: string}[]>(USER_LIST_URL);

	useEffect(() => {
		if (userListRequest.success && userListRequest.data) {
			const _options: IOption[] = userListRequest.success ? userListRequest.data.map((option) => {
				return {id: option.id, label: option.firstName || option.lastName ? `${option.firstName} ${option.lastName}` : 'No name'};
			}) : [];

			setOptions(_options);
		}
	}, [userListRequest.success, userListRequest.data])

	const useMemoFunc = () => {
		return options.filter((o) => selected.includes(o.id));
	}

	const handleOnChange = (_event: any, options: any) => {
		onChange(options.map((option: any) => option.id))
	}

	const selectedValues = React.useMemo(
		useMemoFunc, [options, selected],
	);

	return userListRequest.success ? <Autocomplete
			sx={{width: "500px"}}
			multiple
			loading={userListRequest.loading}
			id="connected-users"
			onChange={handleOnChange}
			options={options}
			value={selectedValues}
			renderInput={(params) => <TextField {...params} label="Connected Users" />}
		/> :
		<Loading />
}
