//Node Modules
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';

//Material UI
import { Box } from '@mui/material';

//Internal
import { ResponseHandler } from '../../Shared/components';
import { useFetch } from '../../Shared/hooks/useFetch';
import TitleBar from '../../Shared/components/TitleBar';
import { OptionsNav } from './NodeDetails/OptionsNav';
import { NodeDetailsContent } from './NodeDetails/NodeDetailsContent';
import { NodeDetailsResp } from '../types';

export const NodesDetails = ({ nodeType }: any) => {
	const params = useParams();
	const { id } = params;

	const [tab, setTab] = useState(0);

	const nodeDetailsRequest = useFetch<NodeDetailsResp>(`${nodeType.route}/${id}`, true, [id]);

	return (
		<ResponseHandler request={nodeDetailsRequest}>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<TitleBar title={nodeDetailsRequest.data?.node.name} />
				<Box sx={{ padding: '0px 50px' }}>
					<Box
						sx={{
							borderRadius: '20px',
							width: window.innerWidth - 100 - 250,
							height: window.innerHeight - 100 - 50,
							background: '#ffffff',
							display: 'flex',
							overflow: 'hidden',
							border: '2px solid #00000023',
						}}
					>
						<OptionsNav tab={tab} setTab={setTab} />
						<NodeDetailsContent tab={tab} id={id} nodeType={nodeType} data={nodeDetailsRequest.data} />
					</Box>
				</Box>
			</Box>
		</ResponseHandler>
	);
};
