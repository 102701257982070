//Node Modules
import React, { ReactElement, useEffect } from 'react';

//Internal
import { EDIT_USER_ENTITIES_URL } from '../../../Shared/constants/routes';
import { ChildSelectProps } from '../../../Shared/interfaces';
import { ResponseHandler } from '../../../Shared/components';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { SelectConnectedEntities } from './SelectConnectedEntities';

const UserEntityRelationshipSelect = (props: ChildSelectProps): ReactElement => {
	const { id, entityChildSet, setEntityChildSet } = props;

	const requestChildSet = useFetch<string[]>(`${EDIT_USER_ENTITIES_URL}/${id}`);

	useEffect(() => {
		if (!requestChildSet.loading && requestChildSet.data) {
			setEntityChildSet(requestChildSet.data);
		}
	}, [requestChildSet.data, requestChildSet.loading, setEntityChildSet]);

	return (
		<ResponseHandler request={requestChildSet}>
			<SelectConnectedEntities
				selected={entityChildSet}
				onChange={setEntityChildSet}
			/>
		</ResponseHandler>
	);
};

export default UserEntityRelationshipSelect;
