import React, { ReactElement } from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const MobileMenuButton = (props: {open: boolean, setOpen: (value: boolean) => void}): ReactElement => {
	const { open, setOpen } = props;

	const handleDrawerToggle = () => {
		setOpen(!open);
	}

	return (
		<IconButton
			color="inherit"
			aria-label="open drawer"
			edge="start"
			onClick={handleDrawerToggle}
			sx={{
				background: 'rgb(236,236,236)',
				position: 'absolute',
				top: '1.25rem',
				left: '1.6rem',
				mr: 2,
				display: { md: 'none' },
				'z-index': '100',
				marginTop: '10px',
				marginLeft: '10px',
				'&:hover': {
					background: 'rgb(218,232,225)',
				}
			}}
		>
			<MenuIcon />
		</IconButton>
	);
}

export default MobileMenuButton;