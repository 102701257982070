import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { CARD_LIST_URL } from '../../../Shared/constants/routes';
import { ResponseHandler } from '../../../Shared/components';
import { useFetch } from '../../../Shared/hooks/useFetch';
import useMediaQuery from '@mui/material/useMediaQuery';

export const SelectConnectedCards = (props: { selected: string[]; onChange: any }): React.ReactElement => {
	const { selected, onChange } = props;
	const [fetchedOptions, setFetchedOptions] = useState([]);

	const narrowScreen = useMediaQuery('(max-width:600px)');

	const requestAllCards = useFetch(CARD_LIST_URL);

	const handleOnChange = (_event: any, options: any) => {
		onChange(options.map((option: any) => option.id));
	};

	useEffect(() => {
		if (requestAllCards.success && requestAllCards.data) {
			const options = requestAllCards.success
				? requestAllCards.data.map((option: any) => {
					return {
						id: option.id,
						label: option.name || 'No name',
					};
				})
				: [];

			setFetchedOptions(options);
		}
	}, [requestAllCards.loading, requestAllCards.data, requestAllCards.success])

	return (
		<ResponseHandler request={requestAllCards}>
			<Autocomplete
				disablePortal={narrowScreen}
				disableCloseOnSelect={narrowScreen}
				size="small"
				multiple
				loading={requestAllCards.loading}
				id="connected-cards"
				onChange={handleOnChange}
				options={fetchedOptions}
				value={[...fetchedOptions.filter((o: any) => selected.includes(o.id))]}
				renderInput={(params) => <TextField {...params}  label="" />}
				sx={{minHeight: narrowScreen ? '200px' : 'auto', maxHeight: '200px', overflow: 'hidden'}}
				componentsProps={{}}
			/>
		</ResponseHandler>
	);
};