//Internal
import { decodeJWT } from './jwt';

/**
 * Checks if a given token has expired.
 * @param token The token to check.
 * @returns true if token has expired. Otherwise, return false.
 */
export const checkTokenExpiration = (token: string): boolean => {
	const {exp} = decodeJWT(token);
	if (typeof exp !== 'number') {
		throw new Error('Invalid token expiration');
	}
	const currentDate: Date = new Date();

	// JWT exp is in seconds
	return exp * 1000 < currentDate.getTime();
};
