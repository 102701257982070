//Node Modules
import React, { ReactElement, useEffect } from 'react';

//Internal
import { ENTITIES_ROOT_URL } from '../../../Shared/constants/routes';
import { CardRelationshipSelectProps } from '../../interfaces';
import { ResponseHandler } from '../../../Shared/components';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { SelectConnectedCards } from './SelectConnectedCards';

interface CardSetObject {
	"embedId": string;
	"name": string;
	"id": string;
}

const CardRelationshipSelect = (props: CardRelationshipSelectProps): ReactElement => {
	const { id, entityCardSet, setEntityCardSet } = props;

	const requestCardSet = useFetch<CardSetObject[]>(`${ENTITIES_ROOT_URL}/${id}/cards`, true, [], { entityId: id });

	useEffect(() => {
		if (requestCardSet.data) {
			setEntityCardSet(requestCardSet.data.map((card) => card.id));
		}
		// @ts-ignore
	}, [requestCardSet.data, requestCardSet.loading, setEntityCardSet]);

	return (
		<ResponseHandler request={requestCardSet}>
			<SelectConnectedCards
				selected={entityCardSet}
				onChange={setEntityCardSet}
			/>
		</ResponseHandler>
	);
};

export default CardRelationshipSelect;
