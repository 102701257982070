//Node Modules
import React, { ReactElement, useState } from 'react';

//Material UI
import { Box, TextField, Typography } from '@mui/material';

//Internal
import { createEntityValidation } from '../../constants/validationSchemas';
import { CreateNodeButton, NodeDialog } from '../../../Shared/components';
import { ENTITIES_CREATE_URL, NODE_LEVELS_URL, NODE_VERTICALS_URL } from '../../../Shared/constants/routes';
import { NodeBase } from '../../../Shared/interfaces';
import EntityGraphFieldsSelector from './EntityGraphFieldsSelector';

interface NewEntity extends NodeBase {
	name: string;
	graphData: {
		vertical: string;
		level: string;
	};
}

export const CreateEntity = (props: { reRenderComponent: Function }): ReactElement => {
	const [name, setName] = useState<string>('');
	const [vertical, setVertical] = useState<string>('');
	const [level, setLevel] = useState<string>('');
	const { reRenderComponent } = props;

	const resetContent = (): void => {
		setName('');
		setVertical('');
		setLevel('');
	};

	const newEntity: NewEntity = {
		name: name,
		graphData: {
			vertical: vertical,
			level: level,
		},
	};

	return (
		<NodeDialog
			ButtonComponent={CreateNodeButton}
			url={ENTITIES_CREATE_URL}
			resetContent={resetContent}
			node={newEntity}
			dialogText={'Create'}
			dialogTitle={'Create Entity'}
			validationSchema={createEntityValidation}
			reRenderComponent={reRenderComponent}
			dialogContentText="Creating entity. Name must be unique. DOMO lookup values may be added in the entity edit screen after creation."
		>
			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '5px' }}>Details</Typography>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Entity Name"
					type="text"
					fullWidth
					variant="standard"
					value={name}
					onChange={(e: any) => setName(e.target.value)}
				/>

				<EntityGraphFieldsSelector reqUrl={NODE_VERTICALS_URL} fieldName={'vertical'} onChange={setVertical} />
				<EntityGraphFieldsSelector reqUrl={NODE_LEVELS_URL} fieldName={'level'} onChange={setLevel} />
			</Box>
		</NodeDialog>
	);
};
