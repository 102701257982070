//Node Module
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';

//Internal
import { NodeIconButton, NodeDialog } from '../../Shared/components';
import { USER_RESTORE_URL } from '../../Shared/constants/routes';
import { NodeBase } from '../../Shared/interfaces';
import { RestoreUserProps } from '../interfaces';

export const RestoreUser = (props: RestoreUserProps): ReactElement => {
	const { reRenderComponent, name, id } = props;

	const user: NodeBase = {
		name: name,
		id: id,
	};

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={USER_RESTORE_URL}
			node={user}
			dialogText={'Restore'}
			dialogTitle={'Restore User'}
			reRenderComponent={reRenderComponent}
			Icon={RestoreIcon}
		>
			<DialogContentText>{`Are you sure you want to restore user ${name}?`}</DialogContentText>
		</NodeDialog>
	);
};
