interface IStoredError {
	message: string;

	[key: string]: any;
}

class ErrorStorageService {
	private maxStorageSize: number = 0.2 * 1024 * 1024; // 0.2 MB in bytes

	storeError(error: IStoredError): void {
		const errorList: IStoredError[] = this.getErrors();
		const serializedError: IStoredError = this.markErrorAsOffline(error);

		const newSize = new Blob([JSON.stringify(serializedError)]).size;

		const totalSize = errorList.reduce((acc, currError) => acc + currError.serializedSize, 0);

		if (totalSize + newSize > this.maxStorageSize) {
			console.warn('Error storage size limit exceeded. Please clear errors.');
			return;
		}

		if (this.shouldBeStored(error, errorList)) {
			errorList.push({ ...serializedError, serializedSize: newSize });
			localStorage.setItem('errors', JSON.stringify(errorList));
		}
		return;
	}

	getErrors(): IStoredError[] {
		return JSON.parse(localStorage.getItem('errors') || '[]');
	}

	clearErrors() {
		localStorage.removeItem('errors');
	}

	private shouldBeStored(error: IStoredError, storedErrors: IStoredError[]): boolean {
		return !storedErrors.some(e => e.message === error.message);
	}

	private markErrorAsOffline(error: IStoredError): IStoredError {
		return {
			...error,
			offline: true,
		};
	}
}

export default ErrorStorageService;
