//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import AddIcon from '@mui/icons-material/Add';
import { Fab, Tooltip } from '@mui/material';
import { StoreState } from '../../../../store/store';
import { ROLES } from '../../../Shared/constants/roles';
import { useSelector } from 'react-redux';

export const CreateCardInstanceButton = (props: { setOpen: Function; disabled: boolean }): ReactElement => {
	const { setOpen, disabled } = props;
	const user = useSelector((state: StoreState) => state.user);
	const userIsAdmin = user.role === ROLES.ADMIN;

	return userIsAdmin ? (
		<Tooltip title="Add Chart">
			<Fab
				onClick={() => setOpen(true)}
				sx={{
					position: 'fixed',
					bottom: '20px',
					right: '152px',
					'&:hover': { background: '#33ad65', color: '#264a5d' },
					'&:disabled': { background: 'rgba(0, 0, 0, 0.12)', color: 'rgba(0, 0, 0, 0.26)' },
					'&.Mui-disabled': {
						pointerEvents: 'auto',
					},
				}}
				color="primary"
				disabled={disabled}
			>
				<AddIcon />
			</Fab>
		</Tooltip>
	) : (
		<></>
	);
};
