import React from 'react';
import { Box, Button } from '@mui/material';
import KVPList from './KVPList';

interface CardFiltersKVPProps {
	KVPs: CardFilterKVP[];
	options: string[];
	onChange: (filters: CardFilterKVP[]) => void;
}

interface CardFilterKVP {
	key: string;
	value: string;
}

const CardFiltersKVP = (props: CardFiltersKVPProps): React.ReactElement => {
	const { KVPs, onChange, options } = props;

	const initKVP: CardFilterKVP = { key: '', value: '' };

	const handleButtonClick = () => {
		onChange([...KVPs, initKVP]);
	};

	return (
		<Box>
			<KVPList KVPs={KVPs} options={options} onChange={onChange} />
			<Button onClick={handleButtonClick}>+ Add Lookup Value</Button>
		</Box>
	);
};

export default CardFiltersKVP;
