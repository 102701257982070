import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { DASHBOARD_LIST_FOR_USER, IMPERSONATED_DASHBOARDS_URL } from '../../../Shared/constants/routes';

interface DashboardSelectList {
	id: string;
	name: string;
}

export const SelectImpersonatedDashboard = (props: {
	currentDashboardId?: string;
	impersonatedUserId: string;
}): React.ReactElement => {
	const { currentDashboardId, impersonatedUserId } = props;
	const navigate = useNavigate();

	const dashboards = useFetch<DashboardSelectList[]>(`${DASHBOARD_LIST_FOR_USER}/${impersonatedUserId}`);

	const onSelect = (event: SelectChangeEvent) => {
		navigate(`${IMPERSONATED_DASHBOARDS_URL}/${event.target.value}`, { state: { impersonatedUserId: impersonatedUserId } });
	};

	return (
		<div>
			{dashboards.success && (
				<Select onChange={onSelect} value={currentDashboardId as any}>
					{dashboards.data.map((dashboard, index) => {
						return (
							<MenuItem
								selected={currentDashboardId === dashboard.id}
								key={`dashboard-${index}`}
								value={dashboard.id}
							>
								{dashboard.name}
							</MenuItem>
						);
					})}
				</Select>
			)}
		</div>
	);
};
