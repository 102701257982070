import { ReactElement } from 'react';
import { Box } from '@mui/material';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { DashboardItem } from './DashboardItem';

const routeIcons = (routeName: string) => {
	switch (routeName) {
		default:
			return DashboardRoundedIcon;
	}
};

const scrollStyles = {
	direction: 'rtl',
	maxHeight: '300px',
	overflowX: 'hidden',
	overflowY: 'scroll',
	'&::-webkit-scrollbar': {
		width: '6px',
	},
	'&:hover::-webkit-scrollbar-thumb': {
		visibility: 'visible',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: 'rgba(128, 128, 128, 0.4)',
		borderRadius: '50px',
		backgroundClip: 'content-box',
		visibility: 'hidden',
	},
	'&::-webkit-scrollbar-track': {
		borderRadius: '50px',
	},
};

export const DashboardItems = (props: { dashboards: any; scrollable?: boolean }): ReactElement => {
	const { dashboards, scrollable } = props;

	return (
		<Box sx={scrollable ? scrollStyles : { overflow: 'hidden' }}>
			<Box sx={{ direction: 'initial' }}>
				{dashboards.map((dashboard: any) => {
					const dashboardData = {
						name: dashboard.name,
						id: dashboard.id,
						route: `/dashboards/${dashboard.id}`,
						reqAdmin: false,
					};

					return <DashboardItem key={dashboard.name} route={dashboardData} RouteIcon={routeIcons(dashboard.name)} />;
				})}
			</Box>
		</Box>
	);
};
