import React, { ReactElement, useEffect, useState } from 'react';
import { useFetch } from '../../Shared/hooks/useFetch';
import { useDispatch } from 'react-redux';
import { apiSettingsSlice } from '../../../store/apiSettingsReducer';
import { ADMIN_SETTINGS_URL } from '../../Shared/constants/routes';
import useNetworkStatus from '../../Shared/hooks/useNetworkStatus';
import ErrorReSender from '../../Shared/components/ErrorReSender';

const TIME_INTERVAL = 15000;

type AppSettingsProps = {
	children: React.ReactNode;
}

const PeriodicUpdater = (props: AppSettingsProps): ReactElement => {
	const { children } = props;
	const [reRender, setReRender] = useState<number>(0);
	const onLine = useNetworkStatus();
	const dispatch = useDispatch();

	const request = useFetch(ADMIN_SETTINGS_URL, true, [reRender], null, false, true);

	const forceReRender = () => setReRender(reRender + 1);

	useEffect(() => {
		if (request.success && request.data) {
			dispatch(apiSettingsSlice.actions.update(request.data));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request.success, request.data]);

	useEffect(() => {
		const intervalId = setInterval(forceReRender, TIME_INTERVAL);
		return () => clearInterval(intervalId);
	});

	return <div>
		<ErrorReSender isOnline={onLine} />
		{children}
	</div>;
};

export default PeriodicUpdater;
