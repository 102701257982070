//Node Modules
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

//Material
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, SimplePaletteColorOptions, ThemeProvider } from '@mui/material/styles';

//Internal
import { Auth0Provider } from './modules/Core/components';
import reportWebVitals from './reportWebVitals';
import { configureStoreWithPersisted } from './store/store';
import App from './App';

const { persistor, store } = configureStoreWithPersisted();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

declare module '@mui/material/styles' {
	interface Palette {
		tertiary: SimplePaletteColorOptions;
	}
	interface PaletteOptions {
		tertiary: SimplePaletteColorOptions;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		tertiary: true;
	}
}

const { palette } = createTheme();

export const theme = createTheme({
	palette: {
		primary: {
			main: '#014C83',
			contrastText: '#fefefe',
		},
		secondary: {
			main: '#0E916F',
		},
		tertiary: palette.augmentColor({
			color: {
				main: '#fefefe',
				contrastText: '#014C83',
			},
		}),
	},
});

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={theme}>
				<CssBaseline>
					<SnackbarProvider hideIconVariant>
						<Auth0Provider>
							<App />
						</Auth0Provider>
					</SnackbarProvider>
				</CssBaseline>
			</ThemeProvider>
		</PersistGate>
	</Provider>,
);

reportWebVitals();
