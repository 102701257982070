import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface SelectorProps {
	options: string[];
	onChange: (value: string) => void;
}

const Selector = (props: SelectorProps) => {
	const { options, onChange } = props;

	const handleChange = (event: SelectChangeEvent) => {
		const value = event.target.value;
		onChange(value);
	};

	return (
		<Select onChange={handleChange} fullWidth>
			{options.map((option: string, index: number) => {
				return (
					<MenuItem key={option + index} value={option}>
						{option}
					</MenuItem>
				);
			})}
		</Select>
	);
};

export default Selector;
