//Node Modules
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

//Internal
import { buildHttpRequest, checkTokenExpiration } from '../../../utils';
import { FetchState, SubmitState } from '../interfaces';
import { useErrorBoundary } from 'react-error-boundary';
import { logoutConfig } from '../../../utils/auth0Utils';
import { useSnackbar } from 'notistack';

const { REACT_APP_AUTH0_AUDIENCE, REACT_APP_AUTH0_SCOPE, REACT_APP_API_URL } = process.env;

const initState: FetchState = {
	data: null,
	success: false,
	error: null,
	loading: false,
};

export const useSubmit = <T = any>(url: string, payload: any = {}, multipart: boolean = false): SubmitState<T> => {
	const fullURL: string = `${REACT_APP_API_URL}${url}`;

	const isMounted = useRef<boolean>(true);
	const [state, setState] = useState<FetchState>(initState);
	const [accessToken, setAccessToken] = useState<string>('');

	const { logout, getAccessTokenSilently } = useAuth0();
	const { showBoundary } = useErrorBoundary();

	const getToken = async (): Promise<string> => {
		const token = await getAccessTokenSilently({
			authorizationParams: {
				audience: REACT_APP_AUTH0_AUDIENCE,
				scope: REACT_APP_AUTH0_SCOPE,
			}
		});
		setAccessToken(token);

		return token;
	};

	const resetState = () => {
		setState(initState);
	};

	const callAPI: Function = useCallback(async () => {
		try {
			setState((prevState) => ({ ...prevState, isLoading: true }));
			let token = accessToken;
			const passJWT = true;
			let json;

			if (passJWT) {
				if (!accessToken || checkTokenExpiration(accessToken)) {
					token = await getToken();
				}
			}

			const response: Response = await fetch(fullURL, buildHttpRequest(payload, passJWT, token, true, multipart));
			const stringBody: string = await response.text();

			try {
				json = JSON.parse(stringBody);
			} catch (error: any) {
				json = stringBody;
			}

			if (isMounted.current) {
				const message = response.status < 400 ? null : parseErrorMessage(response, stringBody);
				setState({
					data: json,
					success: response.status < 400,
					error: message ? { message: message } : null,
					loading: false,
				});
			}
			//TODO - Error Typing
		} catch (e) {
			showBoundary({ message: e });
			logout(logoutConfig).then();
		}

		// eslint-disable-next-line
	}, [url, payload]);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	return { state, callAPI, resetState };
};

const parseErrorMessage = (response: Response, stringBody: string): string => {
	try {
		const json: { RetDet?: any } = JSON.parse(stringBody);
		if (!!json.RetDet) {
			return json.RetDet.message;
		} else {
			return `${response.status}: ${response.statusText}`
		}
	} catch (_error: any) {
		return `${response.status}: ${response.statusText}`
	}
}

export const HandleResponseWithSnack = (props: {state: FetchState, resetState: any}): React.ReactElement => {
	const {state, resetState} = props;
	const snackbar = useSnackbar();

	const handleResponse = (currentState: any) => {
		if (!currentState.loading && currentState.data !== null) {
			if (currentState.success) {
				snackbar.enqueueSnackbar({message: 'Success', variant: 'success'})
			} else {
				snackbar.enqueueSnackbar({message: 'Error occurred', variant: 'error'})
			}
			resetState();
		}
	}

	useEffect(() => {
		handleResponse(state);
	});

	return <></>
}
