import { AccommodatedByKey, ListData } from './types';


export const accommodateByKey = (data: ListData[], key: keyof ListData): AccommodatedByKey  => {
	return data.reduce<AccommodatedByKey>((acc, item) => {
		if (!(key in item)) {
			item[key] = `blank ${key}`
		}
		const accKey = item[key];
		if (!acc[accKey]) {
			acc[accKey] = [];
		}
		acc[accKey].push(item);
		return acc;
	}, {});
}
