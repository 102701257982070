//Node Modules
import React, { ReactElement, useEffect, useState } from 'react';

//Internal
import { NODE_TYPES } from '../Shared/constants/NodeTypes';
import NodeList, { NodeListSearchCriteria } from '../Shared/components/NodeManagement/NodeList';
import { Box, Fab, Tooltip, useTheme } from '@mui/material';
import { debounce } from '../../utils';
import { useSubmit } from '../Shared/hooks/useSubmit';
import { NODE_DELETE_REDUNDANT_URL } from '../Shared/constants/routes';
import SearchBar from '../Shared/components/NodeManagement/SearchBar';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ErrorBoundary from '../Core/components/ErrorBoundary/ErrorBoundary';
import { useSnackbar } from 'notistack';

const ManageNodes = (): ReactElement => {
	const { state, callAPI, resetState } = useSubmit(NODE_DELETE_REDUNDANT_URL);
	const [reRender, setRerender] = useState<boolean>(false);
	const [searchCriteria, setSearchCriteria] = useState<NodeListSearchCriteria>({ property: 'name', value: '' });
	const theme = useTheme();
	const updateSearchCriteria = debounce((args: Partial<NodeListSearchCriteria>) =>
		setSearchCriteria({ ...searchCriteria, ...args }),
	);

	const snackbar = useSnackbar();

	const handleResponse = (currentState: any) => {
		if (!currentState.loading && currentState.data !== null) {
			if (currentState.success) {
				snackbar.enqueueSnackbar({message: 'Success', variant: 'success'})
			} else {
				snackbar.enqueueSnackbar({message: 'Error occurred', variant: 'error'})
			}
			resetState();
		}
	}

	const handleClickDelete = () => {
		callAPI();
	};

	const reRenderComponent = (): void => {
		setRerender(!reRender);
	};

	useEffect(() => {
		handleResponse(state);
	});

	return (
		<ErrorBoundary>
			<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
				<SearchBar
					setSearchCriteria={updateSearchCriteria}
					nodeType={NODE_TYPES.Node}
					reRenderComponent={reRenderComponent}
				/>
				{/* <Button sx={{ margin: '5px 30%' }} variant="contained" color={'error'} onClick={handleClickDelete}>
					Delete Redundant Nodes
				</Button> */}
				<Tooltip title="Delete Redundant Nodes">
					<Fab
						onClick={handleClickDelete}
						sx={{
							position: 'fixed',
							bottom: '20px',
							right: '6%',
							color: theme.palette.error.main,
							background: theme.palette.error.contrastText,
							'&:hover': { background: theme.palette.error.main, color: theme.palette.error.contrastText },
						}}
						aria-label="delete"
					>
						<DeleteOutlineIcon />
					</Fab>
				</Tooltip>
				<NodeList
					nodeType={NODE_TYPES.Node}
					searchCriteria={searchCriteria}
					reRenderComponent={reRenderComponent}
					reRender={reRender}
				/>
			</Box>
		</ErrorBoundary>
	);
};

export default ManageNodes;
