//Node Modules
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

//Material UI
import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { iconWidth, mainColor, selectedColor } from './styles';

//Internal
import { Link } from 'react-router-dom';

export const NavigationItem = (props: { route: any; RouteIcon: any }): ReactElement => {
	const { route, RouteIcon } = props;
	const location = useLocation();

	return (
		<Link key={route.name} to={route.route} style={{ textDecoration: 'none' }}>
			<Box>
				<ListItemButton
					key={route.name}
					sx={{
						padding: '8px 16px 8px 28px',
						height: '44px',
						background: location.pathname === route.route ? '#E6F4FF' : 'inherit',
						borderRight: location.pathname === route.route ? '2px solid #1677FF' : 'none',
						'&:hover': {
							background: '#E6F4FF',
						},
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', fontSize: '10px' }}>
						<ListItemIcon
							sx={{
								minWidth: 'unset',
								marginRight: '10px',
							}}
						>
							<RouteIcon
								sx={{ width: iconWidth, color: location.pathname === route.route ? selectedColor : mainColor }}
							/>
						</ListItemIcon>
						<ListItemText
							disableTypography={true}
							primary={route.name}
							sx={{
								'&.MuiListItemText-root': {
									fontSize: '14px',
									color: location.pathname === route.route ? selectedColor : mainColor,
								},
							}}
						/>
					</Box>
				</ListItemButton>
			</Box>
		</Link>
	);
};
