import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DashboardState {
	dashboards: any[];
	selectedDashboard: string;
}

const initialDashboardState: DashboardState = {
	dashboards: [],
	selectedDashboard: '',
};

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState: initialDashboardState,
	reducers: {
		update: (state, action: PayloadAction<DashboardState>) => {
			return { ...state, ...action.payload };
		},
		remove: (state, action: PayloadAction<{id: string}>) => {
			return {...state, dashboards: [...state.dashboards.filter(dash => dash.id !== action.payload.id)]}
		}
	},
});
