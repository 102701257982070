//Node Modules
import React, { ReactElement, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

//Material UI
import { Box } from '@mui/material';

//Internal
import { logOutMessage } from '../constants/stringConstants';
import { userSlice } from '../../../store/userReducer';
import { logoutConfig } from '../../../utils/auth0Utils';

export const LoggedOut = (): ReactElement => {
	const dispatch = useDispatch();
	const { logout } = useAuth0();

    useEffect(() => {
        dispatch(userSlice.actions.logout());
        logout(logoutConfig).then();
    }, [dispatch, logout]);

	return <Box>{logOutMessage}</Box>;
};
