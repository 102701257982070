import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Internal
import { userSlice, UserState } from './userReducer';
import { dashboardSlice, DashboardState } from './dashboardReducer';
import { apiSettingsSlice, ApiSettingsState } from './apiSettingsReducer';

const persistConfig = {
	key: 'root',
	storage,
};

export interface StoreState {
	user: UserState;
	dashboards: DashboardState;
	apiSettings: ApiSettingsState;
}

const persistedReducer = persistReducer(
	persistConfig,
	combineReducers({ user: userSlice.reducer, dashboards: dashboardSlice.reducer, apiSettings: apiSettingsSlice.reducer }),
);

export const configureStoreWithPersisted = () => {
	const store = configureStore({
		reducer: persistedReducer,
		// Disable serializableCheck error in console for development;
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}),
	});
	const persistor = persistStore(store);
	return { store, persistor };
};
