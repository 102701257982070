import React from 'react';
import { Box, Button } from '@mui/material';
import { CardFilterKVP, KVPComponent } from './KVPListComponents/KVPComponent';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface KVPListProps {
	KVPs: CardFilterKVP[];
	options: string[];
	onChange: (KVPs: CardFilterKVP[]) => void;
}

const KVPList = (props: KVPListProps): React.ReactElement => {
	const { KVPs, options, onChange } = props;
	const theme = useTheme();

	const handleChange = (newKVP: CardFilterKVP, index: number): void => {
		const newKVPs = [...KVPs];
		newKVPs.splice(index, 1, newKVP);
		onChange(newKVPs);
	};

	const handeDeleteClick = (index: number): void => {
		const newKVPs = [...KVPs];
		newKVPs.splice(index, 1);
		onChange(newKVPs);
	};

	return (
		<>
			{KVPs.map((kvp, index) => {
				return (
					// TODO - index key antipattern low priority
					<Box
						id="kvp-box"
						key={`key-box-${index}`}
						sx={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: '10px' }}
					>
						<KVPComponent kvpIndex={index} key={`key-${index}`} kvp={kvp} options={options} onChange={handleChange} />
						<Button
							sx={{
								margin: '8px 0px',
								flexBasis: '10%',
								alignSelf: 'flex-start',
								'&:hover': {
									color: theme.palette.error.main,
									background: 'initial',
								},
							}}
							variant="text"
							color="primary"
							onClick={() => handeDeleteClick(index)}
						>
							<CloseIcon />
						</Button>
					</Box>
				);
			})}
		</>
	);
};

export default KVPList;
