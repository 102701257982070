//Node Modules
import React, { ReactElement, useState } from 'react';

//Material UI
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

//Internal
import { CreateNodeButton, NodeDialog } from '../../Shared/components';
import { createUserValidation } from '../constants/validationSchemas';
import { USER_CREATE_URL } from '../../Shared/constants/routes';
import { ROLES } from '../../Shared/constants/roles';
import { NodeBase } from '../../Shared/interfaces';

interface NewUser extends NodeBase {
	firstName: string;
	lastName: string;
	email: string;
	role: number,
}

export const CreateUser = (props: { reRenderComponent: Function }): ReactElement => {
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [role, setRole] = useState<number>(1);

	const { reRenderComponent } = props;

	const resetContent = (): void => {
		setFirstName('');
		setLastName('');
		setEmail('');
		setRole(1);
	};

	const newUser: NewUser = {
		firstName: firstName,
		lastName: lastName,
		email: email,
		role: role,
	};

	return (
		<NodeDialog
			ButtonComponent={CreateNodeButton}
			url={USER_CREATE_URL}
			resetContent={resetContent}
			node={newUser}
			dialogText={'Create'}
			dialogTitle={'Create User'}
			validationSchema={createUserValidation}
			reRenderComponent={reRenderComponent}
			dialogContentText="Creating user. Default Dashboard should be selected from Public dashboards"
		>
			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px' }}>Personal Details</Typography>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="First Name"
					type="text"
					fullWidth
					variant="standard"
					value={firstName}
					onChange={(e: any) => setFirstName(e.target.value)}
				/>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Last Name"
					type="text"
					fullWidth
					variant="standard"
					value={lastName}
					onChange={(e: any) => setLastName(e.target.value)}
				/>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Email"
					type="text"
					fullWidth
					variant="standard"
					value={email}
					onChange={(e: any) => setEmail(e.target.value)}
				/>
			</Box>

			<Box sx={{ marginTop: '20px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '5px' }}>Permissions</Typography>
				<FormControl style={{ marginTop: '16px' }} fullWidth>
					<InputLabel>Role</InputLabel>
					<Select
						labelId="role-select-label"
						id="role-select"
						label="Role"
						onChange={(e) => setRole(e.target.value as number)}
						value={role}
					>
						<MenuItem value={ROLES.USER}>User</MenuItem>
						<MenuItem value={ROLES.AUTOMATIT}>Automatit</MenuItem>
						<MenuItem value={ROLES.ADMIN}>Admin</MenuItem>
					</Select>
				</FormControl>
			</Box>
		</NodeDialog>
	);
};
