import { useLocation, useNavigate } from 'react-router-dom';
import { ContactSupport } from './ContactSupport';
import React, { FC } from 'react';
import { FallbackProps } from 'react-error-boundary/dist/declarations/src/types';

interface FallbackErrorCustomProps {
	level?: "root" | "route"
	mainMessage?: string;
}

type IFallbackError = FC<FallbackProps & FallbackErrorCustomProps>

export const FallbackError: IFallbackError = ({
	error,
	resetErrorBoundary,
	level = 'route',
	mainMessage }) => {

	const location = useLocation();
	const navigate = useNavigate();

	const { headingMessage, errorWithoutInfoMessage } = getMessages(mainMessage, level);
	const buttonText = getButtonText(level);

	const handleClickHome = () => {
		if (level === 'root') {
			navigate('/');
		} else if (level === 'route') {
			navigate(location.pathname);
		}
		resetErrorBoundary();
	};

	// Styles
	const centerContainer: any = {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		textAlign: 'center',
		padding: '10%',
	};

	const errorMessageStyle = {
		color: 'red',
	};

	const buttonStyle = {
		backgroundColor: 'green',
		color: 'white',
		padding: '10px 20px',
		borderRadius: '5px',
		border: 'none',
		cursor: 'pointer',
		fontSize: 'medium',
		marginTop: '20px',
	};

	return (
		<div style={centerContainer}>
			<h1>{headingMessage}</h1>
			<pre style={errorMessageStyle}>
				{error.message ? error.message : errorWithoutInfoMessage}
			</pre>
			<button style={buttonStyle} onClick={handleClickHome}>
				{buttonText}
			</button>
			<ContactSupport />
		</div>
	);
};

const getMessages = (mainMessage: string | undefined, level: "root" | "route") => {
	const baseErrorMessage: string = 'Sorry, we encountered an issue. Click the button below to return to the homepage';
	const routeErrorMessage: string = 'Sorry, we encountered an issue. Click the button below to refresh the page';
	const errorWithoutInfoMessage: string = 'Unexpected error';

	const isRootLevel = level === 'root';
	const defaultMainMessage = isRootLevel ? baseErrorMessage : routeErrorMessage;
	const headingMessage = mainMessage ? mainMessage : defaultMainMessage;

	return {
		headingMessage,
		errorWithoutInfoMessage
	};
};

const getButtonText = (level: "root" | "route") => {
	return level === 'root' ? 'Back to Home' : 'Refresh';
};