//Node Modules
import React, { ReactElement, ChangeEvent } from 'react';
import { useOutletContext } from 'react-router-dom';
import * as portals from 'react-reverse-portal';

//Material UI
import { Box, TextField, Typography, SelectChangeEvent, Select, MenuItem } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal
import { dumbPluralize } from '../../../../utils';
import { NodeType } from '../../interfaces';

const SearchBar = (props: { nodeType: NodeType; setSearchCriteria: Function; reRenderComponent: Function }): ReactElement => {
	const { nodeType, setSearchCriteria, reRenderComponent } = props;
	const theme = useTheme();
	const { portalNode } = useOutletContext<any>();

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	const handlePropertyChange = (event: SelectChangeEvent): void => {
		setSearchCriteria({ property: event.target.value });
	};

	const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchCriteria({ value: e.target.value });
	};

	return (
		<Box
			id="topBar"
			sx={{
				height: standardScreen ? '100px' : '156px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '0 calc(2% + 10px)',
			}}
		>
			{standardScreen && (
				<>
					<Box
						sx={{
							border: '1px solid rgba(0, 0, 0, 0.23)',
							borderRadius: '50px',
							height: '56px',
							padding: '8px 24px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '190px',
						}}
					>
						<Typography variant="h6" sx={{ fontSize: '24px', color: '#00000087', marginRight: '5px' }}>
							{dumbPluralize(nodeType.name)}
						</Typography>
						{nodeType.createComponent(reRenderComponent)}
					</Box>
					<Box sx={{ display: 'flex', margin: '0 10px', width: '68%' }}>
						<Select
							onChange={handlePropertyChange}
							sx={{ borderRadius: '50px 0px 0px 50px', color: '#00000087', flexBasis: '100px' }}
							defaultValue="name"
						>
							<MenuItem value={'name'}>Name</MenuItem>
							<MenuItem value={'id'}>ID</MenuItem>
						</Select>
						<TextField
							id="outlined-basic"
							variant="outlined"
							fullWidth
							label={
								<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
									<SearchOutlinedIcon sx={{ height: '15px' }} />
									Search
								</Box>
							}
							sx={{ width: '80%' }}
							InputProps={{ sx: { borderRadius: '0px 50px 50px 0px' } }}
							onChange={handleValueChange}
						/>
					</Box>
				</>
			)}
			{!standardScreen && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<Box
						sx={{
							border: '1px solid rgba(0, 0, 0, 0.23)',
							borderRadius: '50px',
							height: '56px',
							padding: '8px 24px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							marginTop: '10px',
						}}
					>
						<Typography variant="h6" sx={{ fontSize: '24px', color: '#00000087', marginRight: '5px' }}>
							{dumbPluralize(nodeType.name)}
						</Typography>
						{nodeType.createComponent(reRenderComponent)}
					</Box>
					<TextField
						id="outlined-basic"
						variant="outlined"
						fullWidth
						label={
							<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
								<SearchOutlinedIcon sx={{ height: '15px' }} />
								Search
							</Box>
						}
						sx={{ width: '100%', marginTop: '10px' }}
						InputProps={{ sx: { borderRadius: '50px' } }}
						onChange={handleValueChange}
					/>
				</Box>
			)}
			{standardScreen && <portals.OutPortal node={portalNode} />}
		</Box>
	);
};

export default SearchBar;
