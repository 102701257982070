//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

//Internal
import { DASHBOARD_LIST_FOR_USER } from '../../Shared/constants/routes';
import { ResponseHandler } from '../../Shared/components';
import { useFetch } from '../../Shared/hooks/useFetch';
import { DashboardSelectList } from '../interfaces';

interface ISelectDefaultDashboard {
	userId: string,
	dashboardId: string | undefined;
	setDashboardId: Function
}

export const DefaultDashboardSelect = (props: ISelectDefaultDashboard): ReactElement => {
	const { userId, setDashboardId, dashboardId } = props;
	const dashboardLabel = 'Default Dashboard';

	const userDashboardsListResponse = useFetch<DashboardSelectList[]>(`${DASHBOARD_LIST_FOR_USER}/${userId}`);

	return (
		<ResponseHandler request={userDashboardsListResponse}>
			<FormControl fullWidth>
				<InputLabel>{dashboardLabel}</InputLabel>
				<Select
					labelId="dashboard-label"
					id="default-dashboard-select"
					label="Default Dashboard"
					onChange={(e) => setDashboardId(e.target.value)}
					value={dashboardId}
				>
					{userDashboardsListResponse?.data?.map(({ id, name }, index) => {
						return (
							<MenuItem selected={id === dashboardId} key={`dashboard-option-${index}`} value={id}>
								{name}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</ResponseHandler>
	);
};
