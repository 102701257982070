//Node Modules
import { ReactElement, useState } from 'react';

//Material UI
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Box,
	Typography,
	useTheme,
} from '@mui/material';

//Internal
import { NodeDialogProps, NodeFull } from '../interfaces';
import { useSubmit } from '../hooks/useSubmit';
import { Loading } from '.';

export const NodeDialog = (props: NodeDialogProps): ReactElement => {
	const {
		ButtonComponent,
		buttonColor,
		children,
		reRenderComponent,
		url,
		node,
		dialogTitle,
		dialogText,
		validationSchema,
		variant,
		dialogButtonText,
		dialogContentText,
		sx,
		successCallback = () => null,
		disableAfter = false,
		Icon = null,
		disabled = false,
		resetContent = () => {},
	} = props;

	const [validationError, setValidationError] = useState<string | null>(null);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const theme = useTheme();

	const { state, callAPI, resetState } = useSubmit<NodeFull>(url, node);

	const handleSubmit = (): void => {
		if (validationSchema) {
			validationSchema
				.validate(node, { abortEarly: true })
				.then(() => {
					submit();
				})
				.catch((error: any) => {
					setValidationError(error.errors[0]);
				});
		} else {
			submit();
		}
	};

	const submit = (): void => {
		resetState();
		setSubmitting(true);
		callAPI();
	};

	const cancel = (): void => {
		resetComponent();
	};

	const confirm = (): void => {
		resetComponent();
		successCallback(state.data);
		reRenderComponent();
	};

	const resetComponent = (): void => {
		setOpen(false);
		setSubmitting(false);
		resetContent();
		setValidationError(null);
		resetState();
	};

	const getRequestOutcome = () => {
		if (state.success) {
			confirm();
		}

		if (state.error) {
			return (
				<>
					<DialogContent>
						{dialogContentText && <DialogContentText>{dialogContentText}</DialogContentText>}
						<Box sx={{ marginTop: dialogContentText ? '20px' : '0' }}> {children}</Box>
					</DialogContent>
					<Box sx={{ display: 'flex', justifyContent: 'center'  }}>
						{validationError && <Typography sx={{ color: theme.palette.error.main, whiteSpace: 'normal', textAlign: 'center', maxWidth: '80%'  }}>{validationError}</Typography>}
						{state.error && <Typography sx={{ color: theme.palette.error.main }}>{state.error.message}</Typography>}
					</Box>

					<DialogActions>
						<Button onClick={cancel}>Cancel</Button>
						<Button variant="contained" onClick={handleSubmit}>
							{dialogText}
						</Button>
					</DialogActions>
				</>
			);
		}

		if (submitting) {
			return (
				<Box sx={{ height: '150px', width: '100%', display: 'flex' }}>
					<Loading />
				</Box>
			);
		}

		return (
			<>
				<DialogContent>
					{dialogContentText && <DialogContentText>{dialogContentText}</DialogContentText>}
					<Box sx={{ marginTop: dialogContentText ? '20px' : '0' }}> {children}</Box>
				</DialogContent>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					{validationError && <Typography sx={{ color: theme.palette.error.main, whiteSpace: 'normal', textAlign: 'center', maxWidth: '80%' }}>{validationError}</Typography>}
				</Box>
				<DialogActions>
					<Button variant="outlined" onClick={cancel}>Cancel</Button>
					<Button variant="contained" onClick={handleSubmit}>
						{dialogText}
					</Button>
				</DialogActions>
			</>
		);
	};

	return (
		<>
			<ButtonComponent
				variant={variant ?? 'text'}
				color={buttonColor ?? 'primary'}
				buttonText={dialogButtonText ?? dialogText}
				setOpen={setOpen}
				disabled={disabled}
				sx={sx}
				Icon={Icon ?? null}
				disableAfter={disableAfter}
			/>
			<Dialog
				id="dialog"
				open={open}
				sx={{
					'.MuiPaper-root': {},
				}}
				onClose={resetComponent}
			>
				<DialogTitle>{dialogTitle}</DialogTitle>
				{getRequestOutcome()}
			</Dialog>
		</>
	);
};
