//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { Box, Typography } from '@mui/material';

//Internal
import { SelectImpersonatedDashboard } from './SelectImpersonatedDashboard';

export const ImpersonatedDashboardBar = (props: {
	currentDashboardId: string;
	impersonatedUserId: string;
	impersonatedUserName: string;
	name?: string;
}): ReactElement => {
	const { currentDashboardId, impersonatedUserId, impersonatedUserName } = props;

	return (
		<Box sx={{ height: '100px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 50px' }}>
			<Box sx={{ display: 'flex' }}>
				<Typography variant="h4">{`Impersonated Dashboard`}</Typography>
				<Typography
					variant="body2"
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'end',
						paddingBottom: '5px',
						opacity: '.8',
						ml: '5px',
					}}
				>{`(${impersonatedUserName})`}</Typography>
			</Box>

			<Box id="container" sx={{ display: 'flex', alignItems: 'center' }} textAlign="center">
				<SelectImpersonatedDashboard currentDashboardId={currentDashboardId} impersonatedUserId={impersonatedUserId} />
				{/* <SetDefaultDashboard defaultDashboardId={currentDashboardId} /> */}
			</Box>
		</Box>
	);
};
