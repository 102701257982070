//Node Modules
import React, { ReactElement, useEffect } from 'react';

//Internal
import { ENTITIES_ROOT_URL } from '../../../Shared/constants/routes';
import { ChildSelectProps } from '../../../Shared/interfaces';
import { ResponseHandler } from '../../../Shared/components';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { SelectConnectedChildren } from './SelectConnectedChildren';

const CardRelationshipSelect = (props: ChildSelectProps): ReactElement => {
	const { id, entityChildSet, setEntityChildSet } = props;

	const requestChildSet = useFetch<string[]>(`${ENTITIES_ROOT_URL}/${id}/sub-entities`, true, [], { entityId: id });

	useEffect(() => {
		if (!requestChildSet.loading && requestChildSet.data) {
			setEntityChildSet(requestChildSet.data)
		}
		// @ts-ignore
	}, [requestChildSet.loading, requestChildSet.data, setEntityChildSet]);

	return (
		<ResponseHandler request={requestChildSet}>
			<SelectConnectedChildren
				selected={entityChildSet}
				onChange={setEntityChildSet}
				excludes={[id]}
			/>
		</ResponseHandler>
	);
};

export default CardRelationshipSelect;
