//Node Modules
import React, { ReactElement } from 'react';
import { useOutletContext } from 'react-router-dom';
import * as portals from 'react-reverse-portal';

//Material UI
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal
import SelectDashboard from './SelectDashboard';
import { CreateDashboard } from '../../Shared/components/CreateDashboard';

export const DashboardBar = (props: any): ReactElement => {
	const { currentDashboardId, provisioned } = props;
	const theme = useTheme();
	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));
	const { portalNode } = useOutletContext<any>();

	return (
		<Box
			sx={{
				height: standardScreen ? '100px' : '156px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '0 calc(2% + 10px)',
				boxSizing: 'border-box',
				background: '#ffffff',
			}}
		>
			{standardScreen && (
				<>
					<Box
						sx={{
							border: '1px solid rgba(0, 0, 0, 0.23)',
							borderRadius: '50px',
							height: '56px',
							padding: '8px 24px',
							display: 'flex',
							justifyContent: 'space-around',
							alignItems: 'center',
							width: '225px',
						}}
					>
						<Typography variant="h6" sx={{ fontSize: '24px', color: '#00000087' }}>
							{'Dashboards'}
						</Typography>
						{provisioned && <CreateDashboard reRenderComponent={() => null} />}
					</Box>
				</>
			)}
			{!standardScreen && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<Box
						sx={{
							border: '1px solid rgba(0, 0, 0, 0.23)',
							borderRadius: '50px',
							height: '56px',
							padding: '8px 24px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							marginTop: '10px',
						}}
					>
						<Typography variant="h6" sx={{ fontSize: '24px', color: '#00000087', marginRight: '5px' }}>
							{'Dashboards'}
						</Typography>
						{provisioned && <CreateDashboard reRenderComponent={() => null} />}
					</Box>
					<Box
						id="container"
						sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: '10px' }}
						textAlign="center"
					>
						<SelectDashboard sx={{ width: '100%' }} currentDashboardId={currentDashboardId} />
					</Box>
				</Box>
			)}
			{standardScreen && <portals.OutPortal node={portalNode} />}
		</Box>
	);
};
