//Node Modules
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

//Material UI
import { Box, IconButton, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Internal

const TitleBar = (props: { title: string }): ReactElement => {
	const { title } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box
			sx={{
				height: standardScreen ? '100px' : '50px',
				minHeight: standardScreen ? '100px' : '50px',
				display: 'flex',
				justifyContent: standardScreen ? 'space-between' : 'center',
				alignItems: 'center',
				padding: standardScreen ? '0 50px' : '0px',
				marginTop: standardScreen ? '0' : '5px',
				position: 'relative',
			}}
		>
			<IconButton
				sx={{
					position: 'absolute',
					top: standardScreen ? '25px' : '0px',
					left: '2%',
					height: standardScreen ? '50px' : '50px',
					width: standardScreen ? '50px' : '50px',
					'&:hover': {
						color: theme.palette.primary.main,
					},
				}}
				onClick={() => navigate(-1)}
			>
				<ArrowBackIcon />
			</IconButton>
			<Typography variant="h4" sx={{ fontSize: standardScreen ? '30px' : '24px', marginLeft: 'calc(25px + 2%)' }}>
				{title}
			</Typography>
		</Box>
	);
};

export default TitleBar;
