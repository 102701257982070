import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { CARD_DOMO_DATASETS } from '../../constants/routes';
import { useFetch } from '../../hooks/useFetch';
import { debounce } from '../../../../utils';

interface DOMODatasetSelectorProps {
	onChange: (value: string) => void;
}

interface APIDatasetResponseObj {
	id: string;
	name: string;
}

interface SelectOptions {
	label: string;
	id: string;
}

const DOMODatasetSelector = (props: DOMODatasetSelectorProps): React.ReactElement => {
	const { onChange } = props;
	const [datasetName, setDatasetName] = useState<string>('');
	const [DSs, setDSs] = useState<SelectOptions[]>([]);

	const response = useFetch(CARD_DOMO_DATASETS, true, [datasetName], { datasetName: datasetName }, true);

	useEffect(() => {
		if (!response.loading && response.success) {
			const options: SelectOptions[] = response.data.map((ds: APIDatasetResponseObj) => {
				return { label: ds.name, id: ds.id };
			});
			setDSs(options);
		}
	}, [response.loading, response.data, response.success]);

	const handleChange = (_event: React.SyntheticEvent, option: SelectOptions | null) => {
		if (option) {
			onChange(option.id);
		}
	};

	const handleInputChange = (_event: any, value: string) => {
		setDatasetName(value);
	};

	return (
		<Autocomplete
			loading={response.loading}
			disablePortal
			id="domo-datasets"
			options={DSs}
			onChange={handleChange}
			onInputChange={debounce(handleInputChange)}
			isOptionEqualToValue={(option: SelectOptions, value: SelectOptions) => {
				return option.id === value.id
			}}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.id}>
						{option.label}
					</li>
				);
			}}
			renderInput={(params) => <TextField {...params} label="Dataset Name" />}
		/>
	);
};

export default DOMODatasetSelector;
