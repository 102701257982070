import React, { FC, ReactElement } from 'react';
import { ErrorBoundary as ReactErrorBoundaryLib } from 'react-error-boundary';
import { FallbackProps } from 'react-error-boundary/dist/declarations/src/types';
import { BackendLogger } from '../../../../utils/BackendLogger';
import { FallbackError } from './FallbackError';

interface ErrorBoundaryProps {
	children: ReactElement;
	level?: "root" | "route";
}

const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children, level }) => {
	const logger = new BackendLogger();

	const handleError = (error: any): void => {
		if (error.isHandled) {
			return;
		}
		logger.logError(error);
		return;
	};

	return (
		<ReactErrorBoundaryLib
			fallbackRender={({error, resetErrorBoundary}: FallbackProps) =>
				<FallbackError error={error} resetErrorBoundary={resetErrorBoundary} level={level} />
			}
			onError={handleError}
		>
			{children}
		</ReactErrorBoundaryLib>
	);
};

export default ErrorBoundary;
