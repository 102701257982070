import React, { ReactElement, useState } from 'react';
import {
	Button,
	Collapse,
	FormControl,
	FormLabel,
	ListItemButton,
	ListItemIcon,
	ListItemText, MenuItem, Select,
	SelectChangeEvent,
} from '@mui/material';

import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { SelectConnectedUsers } from './SelectConnectedUsers';

interface CustomDashboardFiltersProps {
	values: any;
	onPublicChange: (args: any) => void;
	onUserChange: (args: any) => void;
	onClearAll: () => void;
}

export const CustomDashboardFilters = (props: CustomDashboardFiltersProps): ReactElement => {
	const { values, onPublicChange, onUserChange, onClearAll } = props;

	const [open, setOpen] = useState<boolean>(false);

	const handlePublicChange = (value: boolean | undefined): void => {
		onPublicChange(value);
	}

	const handleExtendFilters = (): void => {
		setOpen(!open);
	}

	return <div>
		<ListItemButton onClick={handleExtendFilters}>
			<ListItemIcon>
				<ExtendedIcon open={open} />
			</ListItemIcon>
			<ListItemText primary="Custom filters" />
			<ListItemIcon>
				<ExtendedIcon open={open} />
			</ListItemIcon>
		</ListItemButton>

		<Collapse in={open} timeout={'auto'}>
			<FormControl sx={{minWidth: '100%', padding: '0 10%'}}>
				<FormLabel>Public</FormLabel>
				<SelectPublic onChange={handlePublicChange} value={values.isPublic} />
				<FormLabel>Users</FormLabel>
				<SelectConnectedUsers selected={values.users} onChange={onUserChange} />
				<Button variant={'contained'} color={'secondary'} sx={{margin: '5px 0'}} onClick={onClearAll}><ClearAllRoundedIcon  /></Button>
			</FormControl>
		</Collapse>
	</div>
}

const ExtendedIcon = (props: { open: boolean }): ReactElement => {
	const { open } = props;
	return open ? <ExpandCircleDownIcon sx={{transform: 'rotate(180deg)'}}/> : <ExpandCircleDownIcon/>
}

const SelectPublic = (props: { value: boolean | undefined, onChange: (value: any) => void }): ReactElement => {
	const {value, onChange} = props;

	const handleChange = (event: SelectChangeEvent): void => {
		const value = event.target.value;
		switch (value) {
			case '0':
				onChange(undefined);
				return;
			case '1':
				onChange(true);
				return;
			case '2':
				onChange(false);
				return;
			default: return;
		}
	}

	const _value = typeof value === 'undefined' ? '0' : value ? '1' : '2';

	return <Select value={_value} onChange={handleChange}>
		<MenuItem value={'0'}>All</MenuItem>
		<MenuItem value={'1'}>Public</MenuItem>
		<MenuItem value={'2'} >Private</MenuItem>
	</Select>
}
