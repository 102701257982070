import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Collapse, IconButton, List, ListItem, ListItemText } from '@mui/material';

import { ENTITIES_LIST_URL } from '../../../../Shared/constants/routes';
import LevelListItem from './LevelListItem';
import { accommodateByKey } from './utils';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

const VerticalListItem = (props: { name: string; levels: any[]; callback?: () => void }) => {
	const { name, levels, callback } = props;
	const [open, setOpen] = useState(false);

	const titleName = name
		.toLowerCase()
		.split(/[\s-]+/)
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ');

	const accommodatedLevels = accommodateByKey(levels, 'level');

	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
		setOpen(!open);
	};

	const handleClickLink = () => {
		if (callback) {
			callback();
		}
	};

	return (
		<Box sx={{ textDecoration: 'none' }}>
			<Link
				key={'key'}
				style={{ textDecoration: 'none', position: 'relative', padding: '0px' }}
				to={`${ENTITIES_LIST_URL}`}
				state={{ vertical: name }}
			>
				<ListItem
					secondaryAction={
						<IconButton onClick={handleClick}>
							<KeyboardArrowDown fontSize={'small'} sx={{ transform: open ? 'rotate(-0deg)' : 'rotate(-90deg)' }} />
						</IconButton>
					}
					sx={{ paddingLeft: '24px', height: '44px' }}
				>
					<ListItemText
						disableTypography
						sx={{
							ml: '10px',
							color: open ? 'inherit' : 'rgb(38, 38, 38)',
							fontWeight: '400',
							fontSize: '0.875rem',
							lineHeight: '1.57',
							fontFamily: '"Public Sans", sans-serif',
						}}
						primary={titleName}
						onClick={handleClickLink}
					/>
				</ListItem>
			</Link>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List sx={{ width: '100%', maxWidth: 360 }} component="nav" disablePadding>
					{Object.entries(accommodatedLevels).map(([key, _entity], index) => {
						return (
							<Link
								onClick={handleClickLink}
								key={`key-vert-${index}`}
								style={{ textDecoration: 'none', color: 'fff', position: 'relative' }}
								to={`${ENTITIES_LIST_URL}`}
								state={{ vertical: name, level: key }}
							>
								<LevelListItem key={`lev-${key}`} name={key} />
							</Link>
						);
					})}
				</List>
			</Collapse>
		</Box>
	);
};

export default VerticalListItem;
