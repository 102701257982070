//Node Modules
import React, { ReactElement } from 'react';

//Internal
import { NODE_TYPES } from '../Shared/constants/NodeTypes';
import { NodeManagement } from '../Shared/components';
import ErrorBoundary from '../Core/components/ErrorBoundary/ErrorBoundary';

const ManageUsers = (): ReactElement => {
	return (
			<ErrorBoundary>
				<NodeManagement nodeType={NODE_TYPES.User} />
			</ErrorBoundary>
		)
};

export default ManageUsers;
