//Node Modules
import React from 'react';

//Material UI
import { Box, Tab, Tabs } from '@mui/material';

//Internal

export const OptionsNav = (props: { tab: number; setTab: Function }) => {
	const { tab, setTab } = props;
	return (
		<Box
			sx={{
				width: '15%',
				position: 'relative',
				paddingTop: '1.5%',
				'&::after': {
					content: '" "',
					background: '#00000023',
					position: 'absolute',
					right: 0,
					top: '2.5%',
					height: '95%',
					width: '2px',
					borderRadius: '2px',
				},
			}}
		>
			<Tabs
				orientation="vertical"
				value={tab}
				onChange={(e, i) => setTab(i)}
				sx={{ position: 'relative', zIndex: 10 }}
				indicatorColor="primary"
				textColor="primary"
				TabIndicatorProps={{
					style: {
						color: '#D97D54',
					},
				}}
			>
				<Tab
					sx={{ fontWeight: '700', paddingLeft: '30px', alignItems: 'start', textTransform: 'capitalize' }}
					label="Node Details"
				/>
				<Tab
					sx={{ fontWeight: '700', paddingLeft: '30px', alignItems: 'start', textTransform: 'capitalize' }}
					label="Children Nodes"
				/>
				<Tab
					sx={{ fontWeight: '700', paddingLeft: '30px', alignItems: 'start', textTransform: 'capitalize' }}
					label="Parents Nodes"
				/>
			</Tabs>
		</Box>
	);
};
