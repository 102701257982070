//Node Modules
import { Auth0Provider as DefaultAuth0Provider } from '@auth0/auth0-react';
import React, { ReactElement, ReactNode } from 'react';

const { REACT_APP_AUTH0_AUDIENCE, REACT_APP_AUTH0_SCOPE, REACT_APP_AUTH0_CLIENTID, REACT_APP_AUTH0_DOMAIN } = process.env;

export const Auth0Provider = (props: { children: ReactNode }): ReactElement => {
	const { children } = props;

	return (
		<DefaultAuth0Provider
			domain={REACT_APP_AUTH0_DOMAIN}
			clientId={REACT_APP_AUTH0_CLIENTID}
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: REACT_APP_AUTH0_AUDIENCE,
				scope: REACT_APP_AUTH0_SCOPE,
			}}
		>
			{children}
		</DefaultAuth0Provider>
	);
};
