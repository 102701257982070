//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//Internal
import { NodeDialog } from '../../Shared/components';
import { Fab, Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { userSlice } from '../../../store/userReducer';
import { dashboardSlice } from '../../../store/dashboardReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DASHBOARD_REMOVE_RELATION_URL } from '../../Shared/constants/routes';
import { StoreState } from '../../../store/store';
import { ROLES } from '../../Shared/constants/roles';

const DeleteDashboardFab = (props: { setOpen: Function; disabled: boolean }) => {
	const { setOpen, disabled } = props;

	const user = useSelector((state: StoreState) => state.user);
	const userIsAdmin = user.role === ROLES.ADMIN;

	const withTooltip = (
		<Tooltip title="Delete Dashboard">
			<Fab
				disabled={disabled}
				onClick={() => setOpen(true)}
				sx={{
					position: 'fixed',
					bottom: '20px',
					right: '218px',
					'&:hover': { background: '#d11a2a', color: '#332d2d' },
				}}
				color="primary"
			>
				{disabled ? <DeleteForeverIcon /> : <DeleteForeverIcon />}
			</Fab>
		</Tooltip>
	);

	// const withoutTooltip = (
	// 	<Fab
	// 		disabled={disabled}
	// 		onClick={() => setOpen(true)}
	// 		sx={{
	// 			position: 'fixed',
	// 			bottom: '20px',
	// 			right: '218px',
	// 			'&:hover': { background: '#d11a2a', color: '#332d2d' },
	// 		}}
	// 		color="primary"
	// 	>
	// 		{disabled ? <DeleteForeverIcon /> : <DeleteForeverIcon />}
	// 	</Fab>
	// );

	return userIsAdmin ? withTooltip : <></>;
};

export const RemoveRelationDashboard = (props: any): ReactElement => {
	const { reRenderComponent, dashboardId } = props;
	const theme = useTheme();
	const dispatch = useDispatch();
	const userDashboards = useSelector((state: any) => state.dashboards);

	const isComponentDisabled = isFabIconDisabled(userDashboards.dashboards, dashboardId);

	const payload = {
		id: dashboardId,
	};

	const successCallback = (data: any) => {
		dispatch(dashboardSlice.actions.remove({ id: dashboardId as string }));
		dispatch(userSlice.actions.update({ ...data.user }));
	};

	return (
		<NodeDialog
			ButtonComponent={DeleteDashboardFab}
			url={DASHBOARD_REMOVE_RELATION_URL}
			node={payload}
			dialogText={'Submit'}
			dialogTitle={'Delete Dashboard'}
			reRenderComponent={reRenderComponent}
			variant={'contained'}
			successCallback={successCallback}
			dialogButtonText={'Delete'}
			sx={{
				height: '50px',
				width: '125px',
				textDecoration: 'none',
				borderRadius: '50px',
				minWidth: '150px',
				'&:hover': { background: theme.palette.primary.contrastText, color: theme.palette.primary.main },
			}}
			disableAfter={true}
			disabled={isComponentDisabled}
		>
			<DialogContentText>{`Are you sure you want to delete dashboard`}</DialogContentText>
		</NodeDialog>
	);
};

function isFabIconDisabled(dashboards: { id: string }[], dashboardId: string): boolean {
	const userDashboardIds = dashboards.map((dashboard: { id: string }) => dashboard.id);
	return !userDashboardIds.includes(dashboardId);
}
