//Node Modeules
import React, { ReactElement } from 'react';

//Material UI
import { NodeBarIconProps } from '../interfaces';
import { IconButton } from '@mui/material';

export const NodeIconButton = (props: NodeBarIconProps): ReactElement => {
	const { setOpen, sx, Icon } = props;

	return (
		<IconButton
			onClick={(): void => setOpen(true)}
			size="large"
			sx={{
				textTransform: 'capitalize',
				textDecoration: 'none',
				padding: '0',
				margin: '0 10px',
				'&:hover': {
					background: 'none',
				},
				...sx,
			}}
		>
			{Icon ? <Icon sx={{ transform: 'scale(1.2)' }} /> : null}
		</IconButton>
	);
};
