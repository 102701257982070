//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { Box } from '@mui/material';
// import { useTheme } from '@mui/material/styles';

//Internal
import { Link } from 'react-router-dom';
import logo from '../../assets/automatit_logo.svg';

export const CompanySection = (props: any): ReactElement => {
	// const theme = useTheme();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
			<Link style={{ textDecoration: 'none', border: 'none', outline: 'none' }} to="/">
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'baseline',
						alignItems: 'center',
						padding: '16px 0 16px 24px',
						width: '250px',
					}}
				>
					<img src={logo} alt="Logo" style={{ height: '42px', marginRight: '10px' }} />
				</Box>
			</Link>
		</Box>
	);
};
