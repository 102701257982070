import React from 'react';
import { UserState, userSlice } from '../../../../store/userReducer';
import Menu from '@mui/material/Menu';
import { Box, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { StoreState } from '../../../../store/store';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { logoutConfig } from '../../../../utils/auth0Utils';

function UserMenu(props: { menuAnchor: any; setMenuAnchor: Function; open: boolean }) {
	const { menuAnchor, setMenuAnchor, open } = props;
	const dispatch = useDispatch();
	const { logout, user } = useAuth0();
	const theme = useTheme();
	const userState: UserState = useSelector((state: StoreState) => state.user);

	const handleClose = () => {
		setMenuAnchor(null);
	};

	const signOut = (): void => {
		dispatch(userSlice.actions.logout());
		logout(logoutConfig);
	};

	return (
		<Menu
			id="demo-positioned-menu"
			aria-labelledby="demo-positioned-button"
			anchorEl={menuAnchor}
			open={open}
			onClose={handleClose}
			sx={{ padding: 0 }}
			anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			PaperProps={{ sx: { borderRadius: '15px' } }}
		>
			<Box sx={{ borderRadius: '50px', display: 'flex', flexDirection: 'column' }}>
				{/* <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '2px 15px' }}>
					<Typography sx={{ fontSize: '12px', color: '#00000087' }}></Typography>
					<Typography
						onClick={signOut}
						sx={{
							fontSize: '12px',
							color: '#00000087',
							cursor: 'pointer',
							'&:hover': { color: theme.palette.primary.main },
						}}
					>
						Sign out
					</Typography>
				</Box> */}
				<Box sx={{ display: 'flex', padding: '0px 15px', marginTop: '10px' }}>
					<Box sx={{ marginRight: '10px' }}>
						<img
							src={user?.picture}
							referrerPolicy="no-referrer"
							alt="User Icon"
							style={{ maxHeight: '66px', maxWidth: '66px', borderRadius: '50%' }}
						/>
					</Box>
					<Box>
						<Box sx={{ display: 'flex' }}>
							<Typography
								sx={{
									flexBasis: '70%',
									fontWeight: '700',
									color: theme.palette.primary.main,
									marginBottom: '5px',
								}}
							>
								{`${userState.lastName}, ${userState.firstName}`}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<EmailOutlinedIcon sx={{ color: '#00000087', marginRight: '10px', maxHeight: '20px' }} />
							<Typography sx={{ color: '#00000087', marginRight: '10px' }}>{user?.email}</Typography>
						</Box>
					</Box>
				</Box>
				<Typography
					onClick={signOut}
					sx={{
						flexBasis: '30%',
						fontSize: '12px',
						color: '#00000087',
						cursor: 'pointer',
						alignSelf: 'center',
						marginBottom: '5px',
						'&:hover': { color: theme.palette.primary.main },
					}}
				>
					Sign out
				</Typography>
			</Box>
		</Menu>
	);
}

export default UserMenu;
