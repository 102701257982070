//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { Box } from '@mui/material';

//Internal
import { CardFilter } from '../../interfaces';
import { FiltersListProps } from '../../../ManageCards/interfaces';
import Filter from './Filter';

const FilterList = (props: FiltersListProps): ReactElement => {
	const { filters, onFilterChange } = props;

	const handleChange = (newFilter: CardFilter, i: number): void => {
		const newFilters = [...filters];
		newFilters.splice(i, 1, newFilter);
		onFilterChange(newFilters);
	};

	const handleClickDelete = (i: number): void => {
		const newFilters = [...filters];
		newFilters.splice(i, 1);
		onFilterChange(newFilters);
	};

	return (
		<Box>
			{filters.length &&
				filters.map((filter, index) => {
					return (
						<Filter
							key={`filter-form-${index}`}
							filter={filter}
							filterIndex={index}
							onFilterChange={handleChange}
							onDelete={handleClickDelete}
						/>
					);
				})}
		</Box>
	);
};

export default FilterList;
