import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ENTITIES_LIST_URL } from '../../../Shared/constants/routes';
import { ResponseHandler } from '../../../Shared/components';
import { useFetch } from '../../../Shared/hooks/useFetch';
import useMediaQuery from '@mui/material/useMediaQuery';

export const SelectConnectedEntities = (props: { selected: string[]; onChange: any }): React.ReactElement => {
	const { selected, onChange } = props;
	const [fetchedOptions, setFetchedOptions] = useState([]);

	const narrowScreen = useMediaQuery('(max-width:600px)');

	const requestAllEntities = useFetch(ENTITIES_LIST_URL);

	useEffect(() => {
		if (requestAllEntities.success && requestAllEntities.data) {
			const options = requestAllEntities.data.map((option: any) => {
				return {
					id: option.id,
					label: option.name || 'No name',
				};
			});

			setFetchedOptions(options);
		}
	}, [requestAllEntities.loading, requestAllEntities.data, requestAllEntities.success])

	const handleOnChange = (_event: any, options: any) => {
		onChange(options.map((option: any) => option.id));
	};

	return (
		<ResponseHandler request={requestAllEntities}>
			<Autocomplete
				disablePortal={narrowScreen}
				size="small"
				multiple
				loading={requestAllEntities.loading}
				id="connected-entities"
				onChange={handleOnChange}
				options={fetchedOptions}
				value={[...fetchedOptions.filter((o: any) => selected.includes(o.id))]}
				renderInput={(params) => <TextField {...params} label="Connected Entities" />}
				componentsProps={{}}
			/>
		</ResponseHandler>
	);
};
