//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { Box, CircularProgress } from '@mui/material';

//Internal
import { Color } from '../types/Color';

//TODO - Constants
const LoadingStyles = {
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	zIndex: '10',
};

export const Loading = (props: { color?: Color; sx?: Object }): ReactElement => {
	const { color, sx } = props;

	return (
		<Box sx={sx ?? LoadingStyles}>
			<CircularProgress color={color ? color : 'primary'} />
		</Box>
	);
};
