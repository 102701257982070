//Node Modules
import React from 'react';

//Material UI
import { Box, Tab, Tabs } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal

export const OptionsNav = (props: { tab: number; setTab: Function }) => {
	const { tab, setTab } = props;
	const theme = useTheme();

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box
			sx={{
				width: standardScreen ? '15%' : '100%',
				minWidth: standardScreen ? '150px' : '100%',
				position: 'relative',
				paddingTop: '1.5%',
				'&::after': {
					content: '" "',
					background: '#00000023',
					position: 'absolute',
					right: 0,
					top: '0%',
					height: '100%',
					width: '1px',
					borderRadius: '2px',
					display: standardScreen ? 'inherit' : 'none',
				},
			}}
		>
			<Tabs
				orientation={standardScreen ? 'vertical' : 'horizontal'}
				value={tab}
				variant="scrollable"
				scrollButtons="auto"
				onChange={(e, i) => setTab(i)}
				allowScrollButtonsMobile
				sx={{
					position: 'relative',
					zIndex: 10,
					'& .MuiTabs-flexContainer': {
						display: 'flex',
						justifyContent: 'space-between',
					},
				}}
				indicatorColor="primary"
				textColor="primary"
				TabIndicatorProps={{
					style: {
						color: '#D97D54',
					},
				}}
			>
				<Tab
					sx={{
						fontWeight: '700',
						paddingLeft: '30px',
						alignItems: 'start',
						textTransform: 'capitalize',
						textAlign: 'start',
					}}
					label="Card Details"
				/>
				<Tab
					sx={{
						fontWeight: '700',
						paddingLeft: '30px',
						alignItems: 'start',
						textTransform: 'capitalize',
						textAlign: 'start',
					}}
					label="Instances"
				/>
				<Tab
					sx={{
						fontWeight: '700',
						paddingLeft: '30px',
						alignItems: 'start',
						textTransform: 'capitalize',
						textAlign: 'start',
					}}
					label="Linked Users"
				/>
				<Tab
					sx={{
						fontWeight: '700',
						paddingLeft: '30px',
						alignItems: 'start',
						textTransform: 'capitalize',
						textAlign: 'start',
					}}
					label="Linked Businesses"
				/>
			</Tabs>
		</Box>
	);
};
