import { buildHttpRequest } from './buildHttpRequest';
import ErrorStorageService from './ErrorStorageService';
import { LOG_CREATE_URL } from '../modules/Shared/constants/routes';

const { REACT_APP_API_URL } = process.env;

interface ErrorLogObject {
	message: string;
	stackTrace?: string;
	timestamp: string;
	userAgent: string;
	location: string;
	logType: string;
}

export class BackendLogger {

	constructor(
		private errorStorage = new ErrorStorageService(),
	) {
	}

	public logError(error: Error, logType?: string) {
		const errorLog: ErrorLogObject = {
			message: error.message,
			stackTrace: error.stack,
			timestamp: new Date().toISOString(),
			userAgent: window.navigator.userAgent,
			location: window.location.href,
			logType: logType ? logType : 'error',
		};

		this.send(errorLog).then();
	}

	public sendStoredErrors(): void {
		const errors = this.errorStorage.getErrors();
		this.send(errors).then(() => {
			this.errorStorage.clearErrors();
		});
	}

	public getStoredErrors(): object[] {
		return this.errorStorage.getErrors();
	}

	private async send(payload: any) {
		try {
			await fetch(`${REACT_APP_API_URL}${LOG_CREATE_URL}`, buildHttpRequest(payload, true, '', true));
			return;
		} catch (error: any) {
			this.errorStorage.storeError(payload);
			return;
		}
	}
}
