import React, { useEffect } from 'react';
import { BackendLogger } from '../../../utils/BackendLogger';

interface ErrorReSenderProps {
	isOnline: boolean;
}

const ErrorReSender: React.FC<ErrorReSenderProps> = ({ isOnline }) => {
	const logger = new BackendLogger();

	useEffect(() => {
		const storedErrors = logger.getStoredErrors();
		if (storedErrors.length) {
			if (isOnline) {
				logger.sendStoredErrors();
			}
		}
		// eslint-disable-next-line
	}, [isOnline]);

	// This component doesn't render anything
	return null;
};

export default ErrorReSender;