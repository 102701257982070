//Node Modules
import React, { ReactElement, useEffect } from 'react';

//Material UI
import { Box, Typography } from '@mui/material';

//Internal
import { DashboardList } from './DashboardList';
import { ResponseHandler } from '../../../Shared/components';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { DashboardSelectList } from '../../../ManageUsers/interfaces';
import { DASHBOARD_LIST_FOR_USER } from '../../../Shared/constants/routes';
import { dashboardSlice } from '../../../../store/dashboardReducer';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../../store/store';

export const UserSection = (): ReactElement => {
	const dispatch = useDispatch();
	const dashboards = useSelector((state: StoreState) => state.dashboards);

	const dashboardResponse = useFetch<DashboardSelectList[]>(DASHBOARD_LIST_FOR_USER);

	useEffect(() => {
		if (dashboardResponse.success) {
			const initDashboardData = dashboardSlice.getInitialState();

			dispatch(
				dashboardSlice.actions.update(
					dashboardResponse.success
						? { dashboards: dashboardResponse.data, selectedDashboard: dashboards.selectedDashboard }
						: initDashboardData,
				),
			);
		}

		// eslint-disable-next-line
	}, [dashboardResponse.success]);

	// useEffect(() => {
	// 	const _publicDashboards = dashboards.dashboards.filter((dashboard: {isPublic: boolean}) => dashboard.isPublic);
	// 	const _customDashboards = dashboards.dashboards.filter((dashboard: {isPublic: boolean}) => !dashboard.isPublic);
	// 	setPublicDashboards(_publicDashboards);
	// 	setCustomDashboards(_customDashboards);
	// }, [dashboards.dashboards]);

	return (
		<ResponseHandler request={dashboardResponse}>
			<Box sx={{ direction: 'ltr' }}>
				<Typography
					variant="h6"
					sx={{
						fontWeight: '500',
						fontSize: '12px',
						color: '#8C8C8C',
						padding: '0 0 0 24px',
						fontFamily: 'Public-Sans, sans-serif',
						marginBottom: '12px',
						marginTop: '12px',
					}}
				>
					Dashboards
				</Typography>
				<DashboardList dashboards={dashboardResponse.data} open={true} scrollable={true} />
			</Box>
		</ResponseHandler>
	);
};
