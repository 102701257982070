import React from 'react';
import { Box } from '@mui/material';
import { EmbedCard, Loading, ResponseHandler } from '../../Shared/components';
import { useFetch } from '../../Shared/hooks/useFetch';
import { FetchState } from '../../Shared/interfaces';
import { CARD_INSTANCE_DOMO_CRED_URL } from '../../Shared/constants/routes';

const InternalCard = (props: { payload: any; disablePointer: boolean }): React.ReactElement => {
	const { payload, disablePointer } = props;
	const request: FetchState = useFetch(`${CARD_INSTANCE_DOMO_CRED_URL}`, true, null, payload, true);

	return (
		<ResponseHandler
			request={request}
			Fallback={
				<Box sx={{ display: 'flex', height: 'calc(100% - 25px)' }}>
					<Loading />
				</Box>
			}
		>
			<Box id="internalCard" sx={{ height: 'calc(100% - 25px)', width: 'calc(100%)' }}>
				<EmbedCard
					embedUrl={request.data?.embedUrl}
					embedToken={request.data?.embedToken}
					disablePointer={disablePointer}
				/>
			</Box>
		</ResponseHandler>
	);
};

export default InternalCard;
