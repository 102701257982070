import React from 'react';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

interface KeyComponentProps {
	value: string;
	options: string[];
	onChange: (value: string) => void;
	sx?: any;
}

export const KeyComponent = (props: KeyComponentProps): React.ReactElement => {
	const { value, options, onChange, sx } = props;

	const preparedOptions = [...options];

	const handleChange = (event: React.SyntheticEvent, value: string | null) => {
		if (value) {
			onChange(value);
		} else {
			return;
		}
	};

	const filter = createFilterOptions<string>();

	return (
		<Autocomplete
			sx={{ width: '50%', ...sx }}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			freeSolo
			disablePortal
			value={value}
			id="key-options"
			options={preparedOptions}
			onChange={handleChange}
			renderInput={(params) => <TextField {...params} label="Key" />}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);
				const { inputValue } = params;
				const isExisting = options.some((option) => inputValue === option);
				if (inputValue !== '' && !isExisting) {
					filtered.push(inputValue);
				}
				return filtered;
			}}
		/>
	);
};
