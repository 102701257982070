/**
 * buildHttpRequest() takes a payload, a boolean value that determines if the request should include the JWT, the JWT, and a boolean value that determines if the request is a POST or GET request.
 * It returns a RequestInit object.
 * If the passJWT value is true, the request is passed the JWT as an Authorization header.
 * If the boolean value is false, the request is passed only the base headers.
 * If the passJWT value is true, the request is a POST request.
 * If the boolean value is false, the request is a GET request.
 * If the passJWT value is true, the request includes a body that is the payload stringified.
 * If the boolean value is false, the request does not include a body.
 * If multipart is true, the header Content-Type is not set to application/json and the payload request.body is set as is without being stringified. This assumes the payload is already formatted correctly for a multipart request, such as a FormData instance.
 */
export const buildHttpRequest = (payload: any, passJWT: boolean, jwt: string, isPost: boolean, multipart: boolean = false): RequestInit | undefined => {
	let baseHeaders: HeadersInit = {};

	if (!multipart) {
		baseHeaders = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		}
	}

	const authHeaders: HeadersInit = {
		...baseHeaders,
		Authorization: 'Bearer ' + jwt,
	};

	const headers: HeadersInit = passJWT ? authHeaders : baseHeaders;

	const request: RequestInit = {
		method: isPost ? 'POST' : 'GET',
		signal: AbortSignal.timeout(5000),
		headers,
	};

	if (isPost && !multipart) {
		request.body = JSON.stringify(payload);
	} else if (isPost && multipart) {
		request.body = payload
	}

	return request;
};
