//Node Modules
import React, { ReactElement } from 'react';

//Internal
import { ResponseHandlerProps } from '../interfaces';
import { Loading } from './Loading';

//Needs an interface for request
export const ResponseHandler = (props: ResponseHandlerProps): ReactElement => {
	const { children, request, Fallback } = props;

	//DO NOT FORMAT THIS FILE. IT WILL REMOVE () AND BREAK COMPONENT
	return !request.loading ? request.success ? <>{children}</> : <>Failure</> : Fallback ? Fallback : <Loading />;
};
