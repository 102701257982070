//Node Modules
import { useState } from 'react';

export const useWindowWidth = (modifier: number) => {
	const [width, setWidth] = useState(window.innerWidth + modifier);

	useState(() => {
		const listener = () => {
			setWidth(window.innerWidth + modifier);
		};

		window.addEventListener('resize', listener);

		return () => {
			window.removeEventListener('resize', listener);
		};
	});

	return {
		width,
	};
};
