import { useEffect, useState } from 'react';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { ResponseHandler } from '../../../Shared/components';
import { HandleResponseWithSnack, useSubmit } from '../../../Shared/hooks/useSubmit';
import { getFlagValue } from '../utils';
import { Box, Button, FormControl, FormLabel, TextField } from '@mui/material';

export const InactivityTimerSettings = () => {

	const request = useFetch('/admin/settings');

	const [ timer, setTimer ] = useState('');

	const data = {
		inactivity_timer: timer,
	}

	const { callAPI, state, resetState } = useSubmit('/admin/settings', data);

	const onClick = () => {
		callAPI();
	}

	useEffect(() => {
		if (!request.loading && request.success) {
			setTimer(getFlagValue(request.data, 'inactivity_timer') || '300')
		}
	}, [request.loading, request.success, request.data]);

	return <Box sx={{margin: '20px'}}>
		<ResponseHandler request={request}>
			<div>Inactivity Timer</div>
			<FormControl>
				<FormLabel>Max Height</FormLabel>
				<TextField value={timer} onChange={(e) => setTimer(e.target.value)} />
				<Button onClick={onClick} sx={{ margin: '10px' }} color="success" variant="contained">Save</Button>
			</FormControl>
			<HandleResponseWithSnack state={state} resetState={resetState} />
		</ResponseHandler>
	</Box>
}
