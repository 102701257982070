import { ReactElement } from 'react';
import { DashboardItems } from './DashboardItems';

export const DashboardList = (props: { dashboards: any[], open: boolean, scrollable?: boolean }): ReactElement => {
	const { dashboards, open, scrollable } = props;

	return (
		<div>
			{open && <DashboardItems dashboards={dashboards} scrollable={scrollable}/>}
		</div>
	);
};
