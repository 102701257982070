import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { Box, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { ENTITIES_LIST_URL } from '../../../../Shared/constants/routes';
import { iconWidth, mainColor, selectedColor } from '../styles';
import VerticalListItem from './VerticalListItem';
import { ListData } from './types';
import { accommodateByKey } from './utils';

const RootLevelList = (props: { route: string; entities: ListData[]; callback?: () => void }) => {
	const { route, entities, callback } = props;
	const location = useLocation();

	const [open, setOpen] = useState(false);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
		setOpen(!open);
	};

	const handleClickLink = () => {
		if (callback) {
			callback();
		}
	};

	const verticals = accommodateByKey(entities, 'vertical');

	useEffect(() => {
		if (location.pathname !== ENTITIES_LIST_URL) {
			setOpen(false);
		}
	}, [location.pathname]);

	// @ts-ignore
	return (
		<List sx={{ padding: '0' }}>
			<Box sx={{ borderRight: 'none' }}>
				<Link style={{ textDecoration: 'none' }} key={'key'} to={ENTITIES_LIST_URL}>
					<ListItemButton
						sx={{
							padding: '8px 16px 8px 28px',
							height: '44px',
							background: location.pathname === route ? '#E6F4FF' : 'inherit',
							borderRight: location.pathname === route ? '2px solid #1677FF' : 'none',
							'&:hover': {
								background: '#E6F4FF',
							},
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								position: 'relative',
								width: '100%',
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 'unset',
									marginRight: '10px',
								}}
							>
								{/*@ts-ignore*/}
								<BusinessRoundedIcon
									sx={{
										width: iconWidth,
										color: location.pathname === route ? selectedColor : mainColor,
									}}
								/>
							</ListItemIcon>
							<ListItemText
								onClick={handleClickLink}
								primary={'Manage Entities'}
								disableTypography={true}
								sx={{
									'&.MuiListItemText-root': {
										fontSize: '14px',
										color: location.pathname === route ? selectedColor : mainColor,
									},
								}}
							/>
							<IconButton sx={{ padding: '4px', position: 'absolute', right: '0px' }} onClick={handleClick}>
								<KeyboardArrowDown
									fontSize={'small'}
									sx={{
										transform: open ? 'rotate(-0deg)' : 'rotate(-90deg)',
										color: location.pathname === route ? selectedColor : mainColor,
									}}
								/>
							</IconButton>
						</Box>
					</ListItemButton>
				</Link>
				<Collapse in={open} timeout={0} unmountOnExit>
					<List sx={{ width: '100%', maxWidth: 360, padding: '0px 16px 0px 28px' }} component="nav" disablePadding>
						{Object.entries(verticals).map(([key, value], index) => {
							return (
								<List id="testId" key={`vert-list-${key}-${index}`} sx={{ padding: '0px' }}>
									<VerticalListItem
										key={`vert-item-${key}-${index}`}
										name={key}
										levels={value}
										callback={callback}
									/>
								</List>
							);
						})}
					</List>
				</Collapse>
			</Box>
		</List>
	);
};

export default RootLevelList;
