//Node Modules
import { BrowserRouter as Router } from 'react-router-dom';

//Material UI
import { Box } from '@mui/material';

//Internal
import { InactivityDialog } from './modules/Core/components';
import { AppRoutes } from './routes';
import ErrorBoundary from './modules/Core/components/ErrorBoundary/ErrorBoundary';
import PeriodicUpdater from './modules/Core/components/PeriodicUpdater';

const AppStyles = {
	display: 'flex',
	flexGrow: 1,
	backgroundColor: '#ffffff',
};

const App = () => {
	return (
		<Box sx={{ overflow: 'hidden' }}>
			<Router>
				<ErrorBoundary level={'root'}>
					<Box sx={AppStyles}>
						<PeriodicUpdater>
							<InactivityDialog />
							<AppRoutes />
						</PeriodicUpdater>
					</Box>
				</ErrorBoundary>
			</Router>
		</Box>
	);
};

export default App;
