//Node Modules
import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

//Material UI
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';

//Internal
import { inactivityCounter, inactivityInstructions, inactivityTitle } from '../constants/stringConstants';
import { inactivityDialogStyles } from '../styles/inactivityStyles';
import { useInactivityTimer } from '../hooks/useInactivityTimer';
import { useSelector } from 'react-redux';
import { getFlagValueInt } from '../../AdminPanel/Settings/utils';

const DEFAULT_INACTIVITY_TIMER = 300;

export const InactivityDialog = (): ReactElement => {
	const settings = useSelector((state: any) => state.apiSettings);
	const inactivity_timer = getFlagValueInt(settings, 'inactivity_timer') || DEFAULT_INACTIVITY_TIMER;
	const timer = useInactivityTimer(inactivity_timer);
	const { isAuthenticated } = useAuth0();

	return isAuthenticated ? (
		<Dialog open={timer < 30}>
			<DialogTitle>{inactivityTitle}</DialogTitle>
			<DialogContent>
				<Typography sx={inactivityDialogStyles.topDescText}>{timer + inactivityCounter}.</Typography>
				<Typography>{inactivityInstructions}</Typography>
			</DialogContent>
		</Dialog>
	) : (
		<></>
	);
};
