import { useNavigate } from 'react-router-dom';
import { theme } from '../../../index';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

export const UnauthorizedAccessPage = () => {
	const navigate = useNavigate();

	const handleClick = () => navigate('/');


	const boxStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100vw',
		height: '100vh',
		background: '#264a5d',
	};

	const contentStyle = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: theme.spacing(2),
	};

	const textStyle = {
		color: theme.palette.primary.contrastText,
		fontWeight: 500,
		fontSize: '22px',
	};

	return (
		<Box sx={boxStyle}>
			<Box sx={contentStyle}>
				<Typography sx={textStyle}>
					You are not authorized to access this page.
				</Typography>
				<Button variant={'outlined'} color={'info'} onClick={handleClick}>Go Home</Button>
			</Box>
		</Box>
	);
};