import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ApiSettings {
	name: string;
	value: string;
}

export type ApiSettingsState = ApiSettings[];

const initialUserState: ApiSettingsState = [];

export const apiSettingsSlice = createSlice({
	name: 'apiSettings',
	initialState: initialUserState,
	reducers: {
		update: (_state, action: PayloadAction<ApiSettingsState>) => {
			return action.payload;
		},
	},
});
