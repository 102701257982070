//Node Modules
import React, { ReactElement, useState } from 'react';

//Internal
import { Box } from '@mui/material';
import NodeList, { NodeListSearchCriteria } from '../Shared/components/NodeManagement/NodeList';
import { debounce } from '../../utils';
import SearchBar from '../Shared/components/NodeManagement/SearchBar';
import { NODE_TYPES } from '../Shared/constants/NodeTypes';
import { CustomDashboardFilters } from './components/CustomDashboardFilters';
import ErrorBoundary from '../Core/components/ErrorBoundary/ErrorBoundary';

interface DashboardCustomFilters {
	isPublic?: boolean;
	users: string[]
}

const initDashboardCustomFilters: DashboardCustomFilters = {
	users: [],
};

const initSearchCriteria = {property: 'name', value: ''};

const ManageDashboards = (): ReactElement => {
	const [reRender, setRerender] = useState<boolean>(false);

	const [searchCriteria, setSearchCriteria] = useState<NodeListSearchCriteria>(initSearchCriteria);
	const [customFilters, setCustomFilters] = useState<DashboardCustomFilters>(initDashboardCustomFilters);

	const reRenderComponent = (): void => {
		setRerender(!reRender);
	};

	const handlePublicChange = (value?: boolean): void => {
		if (value !== undefined) {
			setCustomFilters({...customFilters, isPublic: value});
		} else {
			const { isPublic, ...rest} = customFilters;
			setCustomFilters(rest);
		}
	}

	const handleUsersChange = (value: string[]) => {
		setCustomFilters({...customFilters, users: value});
	}

	const handleClearAll = (): void => {
		setCustomFilters(initDashboardCustomFilters);
		setSearchCriteria(initSearchCriteria);
	}

	const updateSearchCriteria = debounce((args: Partial<NodeListSearchCriteria>) => setSearchCriteria({...searchCriteria, ...args}));

	return (
		<ErrorBoundary>
			<Box sx={{ display: 'block', flexDirection: 'column', flex: '1' }}>
				<SearchBar setSearchCriteria={updateSearchCriteria} nodeType={NODE_TYPES.Dashboard} reRenderComponent={reRenderComponent} />
				<CustomDashboardFilters onPublicChange={handlePublicChange} onUserChange={handleUsersChange} onClearAll={handleClearAll} values={customFilters} />
				<NodeList
					nodeType={NODE_TYPES.Dashboard}
					searchCriteria={searchCriteria}
					searchParams={customFilters}
					reRenderComponent={reRenderComponent}
					reRender={reRender}
				/>
			</Box>
		</ErrorBoundary>
	);
}

export default ManageDashboards;
