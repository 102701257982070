//Node Modules
import React from 'react';

//Material UI
import { Box } from '@mui/material';
import { NodeType } from '../../../Shared/interfaces';
import { NodeData } from './NodeData';
import RelatedNodesTable from './RelatedNodesTable';
import { NodeDetailsResp } from '../../types';

//Internal

export const NodeDetailsContent = (props: { tab: number; id: string | undefined; nodeType: NodeType; data: NodeDetailsResp }) => {
	const { tab, data } = props;

	return (
		<>
			<Box hidden={tab !== 0} sx={{ width: '85%' }}>
				<NodeData data={data.node} />
			</Box>
			<Box hidden={tab !== 1} sx={{ width: '85%' }}>
				<RelatedNodesTable nodes={data.childrenData} />
			</Box>
			<Box hidden={tab !== 2} sx={{ width: '85%' }}>
				<RelatedNodesTable nodes={data.parentsData} />
			</Box>
		</>
	);
};
