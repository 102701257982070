//Node Modules
import React, { ReactElement, useState } from 'react';

//Material UI
import { Box, TextField, Typography } from '@mui/material';

//Internal
import { CreateNodeButton, NodeDialog } from '../../Shared/components';
import { createCardValidation } from '../constants/validationSchemas';
import { NodeBase } from '../../Shared/interfaces';
import { CARD_CREATE_URL } from '../../Shared/constants/routes';
import SelectColumnName from '../../Shared/components/DOMO/SelectColumnName';

interface NewCard extends NodeBase {
	name: string;
	embedId: string;
	identifierField: string;
}

export const CreateCard = (props: { reRenderComponent: Function }): ReactElement => {
	const [name, setName] = useState<string>('');
	const [embedId, setEmbedID] = useState<string>('');
	const [identifierField, setIdentifierField] = useState<string>('');

	const { reRenderComponent } = props;

	const resetContent = (): void => {
		setName('');
	};

	const newCard: NewCard = {
		name: name,
		embedId: embedId,
		identifierField: identifierField,
	};

	return (
		<NodeDialog
			ButtonComponent={CreateNodeButton}
			url={CARD_CREATE_URL}
			resetContent={resetContent}
			node={newCard}
			dialogText={'Create'}
			dialogTitle={'Create Card 2'}
			dialogContentText="Create new card. Name must be unique. Please include any relevant filter fields for data lookup in DOMO."
			validationSchema={createCardValidation}
			reRenderComponent={reRenderComponent}
		>
			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '0px' }}>Details</Typography>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Card Name"
					type="text"
					fullWidth
					variant="standard"
					value={name}
					onChange={(e: any) => setName(e.target.value)}
				/>
				<TextField
					autoFocus
					margin="dense"
					id="embedId"
					label="Card embedId"
					type="text"
					fullWidth
					variant="standard"
					value={embedId}
					onChange={(e: any) => setEmbedID(e.target.value)}
				/>
				<Box sx={{ margin: '8px 0px' }}>
					<SelectColumnName handleSelect={setIdentifierField} />
				</Box>
			</Box>
		</NodeDialog>
	);
};
