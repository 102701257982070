//Node Modules
import React, { ReactElement, useState } from 'react';

//Material UI
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal
import interact from 'interactjs';
import { DeleteCardInstance } from './DeleteCardInstance';
import InternalCard from './InternalCard';
import { EditCardInstance } from './EditCardInstance';

type BorderType = 'top' | 'bottom' | 'left' | 'right' | 'none';

export const CanvasCard = (props: any): ReactElement => {
	const {
		filterFields,
		cardInstanceId,
		cardInstanceName,
		allowEdit,
		impersonation,
		initMovementCalc,
		index,
		hover,
		setHover,
		positionX,
		positionY,
		width,
		height,
		dropShape,
		resizeShape,
		setShapeRecoveryData,
		disablePointer,
		setDisablePointer,
		scrollHeight,
		scrollPositionY,
		scrollPositionX,
		scrollWidth,
		reRenderComponent,
		sizeRestrictions,
	} = props;
	const theme = useTheme();
	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	const { maxWidth, maxHeight }: { maxWidth?: number; maxHeight?: number } = sizeRestrictions;

	const MAX_WIDTH = maxWidth || 500;
	const MAX_HEIGHT = maxHeight || 500;

	// Use the same value from dashboard data for different purposes;
	const enableDragAndDrop = allowEdit;
	const allowDelete = allowEdit;

	const payload: { cardInstanceId: string; impersonatedUserId?: string } = {
		cardInstanceId: cardInstanceId,
	};

	const impersonationMod = impersonation && impersonation.impersonation;

	if (impersonationMod) {
		payload.impersonatedUserId = impersonation.impersonatedUserId;
	}

	var gridTarget = interact.snappers.grid({
		x: 50,
		y: 50,

		range: 30,
	});

	const [interacting, setInteracting] = useState(false);
	const [hoverBorder, setHoverBoarder] = useState<BorderType>('none');

	const setInteractability = (enabled: boolean) => {
		setInteracting(enabled);
		setHover((currentHover: any) => ({
			...currentHover,
			enabled: enabled,
		}));
		setDisablePointer(enabled);
	};

	const handleHover = (e: any) => {
		if (!hover.enabled) return;

		let border: BorderType = 'none';

		const left = e.clientX - positionX - 249 + scrollPositionX; //x position within the element.
		const top = e.clientY - positionY - 130 + scrollPositionY; //y position within the element.
		const right = width - left;
		const bottom = height - top;

		if (left < right && left < top && left < bottom) {
			border = 'left';
		} else if (right < left && right < top && right < bottom) {
			border = 'right';
		} else if (top < left && top < right && top < bottom) {
			border = 'top';
		} else {
			border = 'bottom';
		}

		setHover((currentHover: any) => ({
			...currentHover,
			id: cardInstanceId,
			positionX: positionX,
			positionY: positionY,
			border: border,
			currentlyHovering: true,
		}));

		setHoverBoarder(border);
	};

	const handleMouseExit = () => {
		setHover((currentHover: any) => ({
			...currentHover,
			id: '',
			positionX: 0,
			positionY: 0,
			currentlyHovering: false,
		}));

		setHoverBoarder('none');
	};

	const interaction = interact(`.Interactable${index}`);

	if (enableDragAndDrop && standardScreen) {
		interaction
			.draggable({
				origin: 'parent',
				modifiers: [
					interact.modifiers.snap({
						targets: [gridTarget],
						relativePoints: [{ x: 0, y: 0 }],
						endOnly: true,
					}),
				],
				listeners: {
					start() {
						const shapeData = {
							id: cardInstanceId,
							positionX: positionX,
							positionY: positionY,
							height: height,
							width: width,
							scrollHeight: scrollHeight,
							scrollWidth: scrollWidth,
						};

						setShapeRecoveryData(shapeData);
						setInteractability(true);
					},
					move(event) {
						dropShape(index, event);
					},
					end() {
						dropShape(index, null, true);

						initMovementCalc(index, {
							positionX: Math.round(positionX / 50) * 50,
							positionY: Math.round(positionY / 50) * 50,
						});

						setInteractability(false);
					},
				},
			})
			.resizable({
				edges: { top: false, left: false, bottom: true, right: true },
				modifiers: [
					interact.modifiers.restrictSize({
						min: { width: 100, height: 100 },
						max: { width: MAX_WIDTH, height: MAX_HEIGHT },
					}),
				],
				listeners: {
					start() {
						const shapeData = {
							id: cardInstanceId,
							positionX: positionX,
							positionY: positionY,
							height: height,
							width: width,
							scrollHeight: scrollHeight,
							scrollWidth: scrollWidth,
						};

						setShapeRecoveryData(shapeData);
						setDisablePointer(true);
						setInteracting(true);
					},
					move(event) {
						resizeShape(index, event);
					},
					end(event) {
						resizeShape(index, event);

						initMovementCalc(index, {
							width: Math.round(event.rect.width / 50) * 50,
							height: Math.round(event.rect.height / 50) * 50,
						});

						setInteracting(false);
						setDisablePointer(false);
					},
				},
			});
	} else {
		interaction.unset();
	}

	const interactClass = `Interactable${index}`;

	return (
		<Box
			sx={{
				width: standardScreen ? width : 'unset',
				height: standardScreen ? height : 'unset',
				boxSizing: 'border-box',
				position: standardScreen ? 'absolute' : 'initial',
				zIndex: interacting ? 100 : 'auto',
				opacity: interacting ? 0.5 : 1,
				pointerEvents: interacting ? 'none' : 'auto',
				borderLeft: hoverBorder === 'left' ? '4px solid #33ad65' : '4px solid rgba(0, 0, 0, 0)',
				borderRight: hoverBorder === 'right' ? '4px solid #33ad65' : '4px solid rgba(0, 0, 0, 0)',
				borderTop: hoverBorder === 'top' ? '4px solid #33ad65' : '4px solid rgba(0, 0, 0, 0)',
				borderBottom: hoverBorder === 'bottom' ? '4px solid #33ad65' : '4px solid rgba(0, 0, 0, 0)',
				transform: standardScreen ? `translate(${positionX}px, ${positionY}px)` : 'none',
				background: 'transparent',
				padding: '6px',
				cursor: standardScreen ? 'inherit' : 'default',
				flex: standardScreen ? 'none' : '1 1 300px',
				minHeight: standardScreen ? 0 : '300px',
			}}
			className={interactClass}
			onMouseMove={handleHover}
			onMouseLeave={handleMouseExit}
		>
			<Box
				sx={{
					background: '#ffffff',
					borderRadius: '10px',
					overflow: 'hidden',
					padding: '10px',
					boxSizing: 'border-box',
					minHeight: standardScreen ? 0 : '300px',
					height: standardScreen ? height - 8 - 12 : `calc(100% - 8px - 12px)`,
					// border: '1px solid #00000023',
					boxShadow: '0 0 4px 2px #00000010',
				}}
			>
				<Box
					id="top"
					sx={{
						background: '#fff',
						height: '25px',
						color: 'black',
						display: 'flex',
						justifyContent: 'space-between',
						alignContent: 'center',
					}}
				>
					<Typography fontWeight="bold">{cardInstanceName}</Typography>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignContent: 'center',
						}}
					>
						{allowEdit && (
							<EditCardInstance
								reRenderComponent={reRenderComponent}
								cardInstance={{
									id: cardInstanceId,
									cardInstanceName: cardInstanceName,
									filterFields: filterFields,
								}}
							/>
						)}
						{allowDelete && <DeleteCardInstance reRenderComponent={reRenderComponent} id={cardInstanceId} />}
					</Box>
				</Box>

				<InternalCard payload={payload} disablePointer={disablePointer} />
			</Box>
		</Box>
	);
};
