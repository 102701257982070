//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//Internal
import { USER_SET_DASHBOARD_URL } from '../../Shared/constants/routes';
import { NodeDialog } from '../../Shared/components';
import { Fab, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { userSlice } from '../../../store/userReducer';
import { useDispatch, useSelector } from 'react-redux';

const SetDefaultDashboardFab = (props: { setOpen: Function; disabled: boolean }) => {
	const { setOpen, disabled } = props;

	const withTooltip = <Tooltip title="Set As Default Dashboard">
		<Fab
			disabled={disabled}
			onClick={() => setOpen(true)}
			sx={{
				position: 'fixed',
				bottom: '20px',
				right: '86px',
				'&:hover': { background: '#33ad65', color: '#264a5d' },
			}}
			color="primary"
		>
			{disabled ? <StarIcon /> : <StarBorderIcon />}
		</Fab>
	</Tooltip>

	const withoutTooltip =
		<Fab
			disabled={disabled}
			onClick={() => setOpen(true)}
			sx={{
				position: 'fixed',
				bottom: '20px',
				right: '86px',
				'&:hover': { background: '#33ad65', color: '#264a5d' },
			}}
			color="primary"
		>
			{disabled ? <StarIcon /> : <StarBorderIcon />}
		</Fab>

	return disabled ? withoutTooltip : withTooltip
};

export const SetDefaultDashboard = (props: any): ReactElement => {
	const { reRenderComponent, defaultDashboardId, currentDashboardId } = props;
	const theme = useTheme();
	const dispatch = useDispatch();
	const userDashboards = useSelector((state: any) => state.dashboards);

	const isComponentDisabled = isFabIconDisabled(userDashboards.dashboards, currentDashboardId);

	const payload = {
		defaultDashboardId: currentDashboardId,
	};

	const successCallback = (data: any) => {
		dispatch(userSlice.actions.update({ ...data.user }));
	};

	return (
		<NodeDialog
			ButtonComponent={SetDefaultDashboardFab}
			url={USER_SET_DASHBOARD_URL}
			node={payload}
			dialogText={'Submit'}
			dialogTitle={'Set Default Dashboard'}
			reRenderComponent={reRenderComponent}
			variant={'contained'}
			successCallback={successCallback}
			dialogButtonText={'Set Default'}
			sx={{
				height: '50px',
				width: '125px',
				textDecoration: 'none',
				borderRadius: '50px',
				minWidth: '150px',
				'&:hover': { background: theme.palette.primary.contrastText, color: theme.palette.primary.main },
			}}
			disableAfter={true}
			disabled={currentDashboardId === defaultDashboardId || isComponentDisabled}
		>
			<DialogContentText>{`Are you sure you want to change your default dashboard`}</DialogContentText>
		</NodeDialog>
	);
};

function isFabIconDisabled(dashboards: {id: string}[], dashboardId: string): boolean {
	const userDashboardIds = dashboards.map((dashboard: {id: string}) => dashboard.id);
	return !(userDashboardIds.includes(dashboardId));
}
