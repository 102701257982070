//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

//Internal
import { CARD_LIST_FOR_USER } from '../../../Shared/constants/routes';
import { ResponseHandler } from '../../../Shared/components';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { EmbedCard } from '../../../Shared/interfaces';

const InstanceBaseSelect = (props: {
	originCardId: string;
	setOriginCardId: Function;
	impersonatedUserId?: string;
}): ReactElement => {
	const { originCardId, setOriginCardId, impersonatedUserId } = props;

	const fetchURL = impersonatedUserId ? `${CARD_LIST_FOR_USER}/${impersonatedUserId}` : CARD_LIST_FOR_USER;

	const requestCardList = useFetch<EmbedCard[]>(fetchURL, true, null, null, false);

	return (
		<ResponseHandler request={requestCardList} Fallback={<Select disabled sx={{ minWidth: '100%', marginTop: '10px' }} />}>
			<FormControl fullWidth>
				<InputLabel>{'Selected Chart'}</InputLabel>
				<Select
					labelId="chart-label"
					id="default-chart-select"
					label="Selected Chart"
					onChange={(e: SelectChangeEvent) => setOriginCardId(e.target.value)}
					value={originCardId}
				>
					{requestCardList.data?.map(({ id, name }, index: number) => {
						return (
							<MenuItem key={index} value={id}>
								{name}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</ResponseHandler>
	);
};

export default InstanceBaseSelect;
