//Node Modules
import React, { useState } from 'react';

//Material UI
import { Box, TextField, Typography } from '@mui/material';
import { INode } from '../../types';
//Internal

const DataField = (props: { edit: Boolean; label: string; value: string }) => {
	const { edit, label, value } = props;
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: '50%', marginBottom: '25px' }}>
			<Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{label}</Typography>
			{edit ? (
				<TextField id="standard-helperText" defaultValue="Default Value" variant="standard" />
			) : (
				<Typography sx={{ fontWeight: 700, fontSize: '16px' }}>{value}</Typography>
			)}
		</Box>
	);
};

export const NodeData = (props: { data: INode }) => {
	const [edit] = useState<boolean>(false);
	const { data } = props;

	return (
		<Box sx={{ padding: '5% 5%' }}>
			<Box
				sx={{
					padding: '20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					marginBottom: '50px',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Basic Info
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField edit={edit} label={'Node Name'} value={data.name} />
					<DataField edit={edit} label={'Id'} value={data.id} />
					<DataField edit={edit} label={'Status'} value={data.deleted ? 'Disabled' : 'Active'} />
				</Box>
			</Box>
		</Box>
	);
};
