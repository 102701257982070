import React from 'react';
import { ListItem, ListItemText } from '@mui/material';

const LevelListItem = (props: { name: string }) => {
	const { name } = props;

	const titleName = name
		.toLowerCase()
		.split(' ')
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ');

	return (
		<ListItem sx={{ height: '44px' }}>
			<ListItemText
				disableTypography
				sx={{
					paddingLeft: '56px',
					color: 'rgb(38, 38, 38)',
					fontWeight: '400',
					fontSize: '0.875rem',
					lineHeight: '1.57',
					fontFamily: '"Public Sans", sans-serif',
				}}
				primary={titleName}
			/>
		</ListItem>
	);
};
export default LevelListItem;
