//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { Box, Button, Typography } from '@mui/material';

//Internal
import { noFilters } from '../../../ManageCards/constants/stringConstants';
import { SelectCardFiltersProps } from '../../../ManageCards/interfaces';
import { CardFilter } from '../../interfaces';
import FilterList from './FilterList';

export const CardFilters = (props: SelectCardFiltersProps): ReactElement => {
	const { filterFields, onChange } = props;
	const filters: CardFilter[] = filterFields || [];

	const initFilter: CardFilter = { column: '', values: [''], operator: 'IN' };

	const onClick = (): void => {
		onChange([...filters, initFilter]);
	};

	const onFilterChange = (filters: CardFilter[]): void => {
		onChange(filters);
	};

	return (
		<Box>
			{filters.length ? (
				<FilterList onFilterChange={onFilterChange} filters={filters} />
			) : (
				<Typography variant="body2">{noFilters}</Typography>
			)}
			<Button onClick={onClick} sx={{ position: 'flex' }} color="primary" aria-label="edit">
				+ Add Filter
			</Button>
		</Box>
	);
};
