//Node Modules
import React, { ReactElement, useEffect, useState } from 'react';

//Material UI
import { Box } from '@mui/material';

//Internal
import { debounce } from '../../../../utils';
import { NodeType } from '../../interfaces';
import SearchBar from './SearchBar';
import NodeList, { NodeListSearchCriteria } from './NodeList';
import IncludeDeleteToggle from './IncludeDeleteToggle';
import { useLocation } from 'react-router-dom';

export const NodeManagement = (props: { nodeType: NodeType }): ReactElement => {
	const { nodeType } = props;
	const [reRender, setRerender] = useState<boolean>(false);
	const [includeDeleted, setIncludeDeleted] = useState<boolean>(false)
	const [vertical, setVertical] = useState<string>('');
	const [level, setLevel] = useState<string>('');

	const location = useLocation();
    const locationState = location.state || {};

	useEffect(() => {
		setVertical(locationState.vertical || '');
		setLevel(locationState.level || '');
	}, [locationState.vertical, locationState.level]);


	const [searchCriteria, setSearchCriteria] = useState<NodeListSearchCriteria>({property: 'name', value: ''});

	const reRenderComponent = (): void => {
		setRerender(!reRender);
	};

	const updateSearchCriteria = debounce((args: Partial<NodeListSearchCriteria>) => setSearchCriteria({...searchCriteria, ...args}));

	return (
		<Box sx={{ display: 'block', flexDirection: 'column', flex: '1' }}>
			<SearchBar setSearchCriteria={updateSearchCriteria} nodeType={nodeType} reRenderComponent={reRenderComponent} />
			{nodeType.hasSoftDelete && <IncludeDeleteToggle value={includeDeleted} handleClick={setIncludeDeleted} />}
			<NodeList
				nodeType={nodeType}
				searchCriteria={searchCriteria}
				reRenderComponent={reRenderComponent}
				reRender={reRender}
				searchParams={nodeType.hasSoftDelete ? {search: {includeDeleted: includeDeleted, vertical: vertical ? vertical : undefined, level: level ? level : undefined}} : undefined}
			/>
		</Box>
	);
};
