//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { Box } from '@mui/material';
import { NavigationItem } from './NavigationItem';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import StackedBarChartOutlinedIcon from '@mui/icons-material/StackedBarChartOutlined';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import PolylineRoundedIcon from '@mui/icons-material/PolylineRounded';
import ManageEntitiesList from './ManageEntitiesList';

const routeIcons = (routeName: string) => {
	switch (routeName) {
		case 'Manage Users':
			return SupervisorAccountRoundedIcon;
		case 'Dashboards':
			return DashboardRoundedIcon;
		case 'Manage Dashboards':
			return DashboardCustomizeIcon;
		case 'Manage Cards':
			return StackedBarChartOutlinedIcon;
		case 'Manage Nodes':
			return PolylineRoundedIcon;
		default:
			return DashboardRoundedIcon;
	}
};

export const NavigationItems = (props: { routes: any }): ReactElement => {
	const { routes } = props;

	return (
		<Box>
			{routes.map((route: any) => (
				<NavigationItem key={route.name} route={route} RouteIcon={routeIcons(route.name)} />
			))}
			<ManageEntitiesList route={'/entities'} />
		</Box>
	);
};
