import React, { ReactElement, useState } from 'react';
import { Box, Button, Dialog, DialogTitle, Modal, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const { REACT_APP_SUPPORT_EMAIL } = process.env;

export const ContactSupport = (): ReactElement => {

	if (!REACT_APP_SUPPORT_EMAIL) {
		throw new Error('REACT_APP_SUPPORT_EMAIL is not defined');
	}

	const [open, setOpen] = useState(true);

	const handleClick = () => {
		setOpen(false);
	};

	return (
		<Modal open={open} sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			m: 2,
		}}>
			<Dialog open={open}>
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 2,
					padding: '30px',
				}}>
					<DialogTitle>Contact Support</DialogTitle>
					<Typography sx={{ textAlign: 'center' }}>Please contact support for assistance</Typography>
					<Link to={`mailto:${REACT_APP_SUPPORT_EMAIL}`}>Contact Support</Link>
					<Button onClick={handleClick} variant='contained' color='info'>Close</Button>
				</Box>
			</Dialog>
		</Modal>
	);
};
