import React from 'react';
import { TextField } from '@mui/material';

export const ValueComponent = (props: { value: string, onChange: (value: string) => void }): React.ReactElement => {
	const { value, onChange } = props;

	const handleChange = (event: any): void => {
		onChange(event.target.value);
	};

	return <TextField onChange={handleChange} value={value}>{value}</TextField>;
};