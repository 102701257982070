export const getFlagValue = (flags: { name: string, value: string }[] | undefined, name: string): string | null => {
	if (!flags) {
		return null;
	}
	const flag = flags.find((flag) => flag.name === name);
	return !!flag ? flag.value : null;
}

export const getFlagValueInt = (flags: { name: string, value: string }[] | undefined, name: string): number | null => {
	const value = getFlagValue(flags, name);
	return value ? parseInt(value) : null;
}
