import React from 'react';
import { Box, FormControl, FormControlLabel, Switch } from '@mui/material';

interface IncludeDeleteToggleProps {
	handleClick: (value: boolean) => void;
	value: boolean;
}

const IncludeDeleteToggle = (props: IncludeDeleteToggleProps): React.ReactElement => {
	const { value, handleClick } = props;

	return (
		<Box sx={{ width: '96%', 'alignSelf': 'flex-end', pl: 8 }}>
			<FormControl component="fieldset">
				<FormControlLabel
					control={<Switch checked={value} onChange={() => handleClick(!value)} />}
					label="Include Deleted"
				/>
			</FormControl>
		</Box>
	);
}

export default IncludeDeleteToggle;
