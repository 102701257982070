//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { Fab } from '@mui/material';

export const DialogButton = (props: { setOpen: Function; disabled: boolean; sx: any; buttonText: any }): ReactElement => {
	const { setOpen, disabled, buttonText, sx } = props;

	return (
		<Fab
			onClick={() => setOpen(true)}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				background: 'transparent',
				boxShadow: 'none',
				width: '36px',
				height: '36px',
				'&:hover': {
					background: 'transparent',
					color: 'red',
				},
				...sx,
			}}
			color="secondary"
			aria-label="edit"
			disabled={disabled}
		>
			{buttonText}
		</Fab>
	);
};
