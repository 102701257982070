import React from 'react';
import RootLevelList from './RootLevelList';
import { useFetch } from '../../../../Shared/hooks/useFetch';
import { ENTITIES_LIST_URL } from '../../../../Shared/constants/routes';

const ManageEntitiesList = (props: { route: string; callback?: () => void }) => {
	const request = useFetch(ENTITIES_LIST_URL);

	return <RootLevelList route={props.route} entities={request.data || []} callback={props.callback} />;
};

export default ManageEntitiesList;
