//Node Modules
import React, { ReactElement } from 'react';

//Internal
import { NODE_TYPES } from '../Shared/constants/NodeTypes';
import { NodeManagement } from '../Shared/components';
import ErrorBoundary from '../Core/components/ErrorBoundary/ErrorBoundary';

const ManageCards = (): ReactElement => {
	return (
			<ErrorBoundary>
				<NodeManagement nodeType={NODE_TYPES.Card} />
			</ErrorBoundary>
		)
};

export default ManageCards;
