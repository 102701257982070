import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { INode } from '../../types';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function RelatedNodesTable(props: { nodes: INode[] }) {
	const { nodes } = props;
	const navigate = useNavigate();
	const theme = useTheme();

	const [rowsPerPage, setRowsPerPage] = React.useState(15);
	const [page, setPage] = React.useState(0);

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const columns = [
		{ id: 'id', label: 'Id', minWidth: 85 },
		{ id: 'name', label: 'Name', minWidth: 85 },
		{ id: 'isActive', label: 'Active', minWidth: 85 }
	];

	const handleClick = (id: string) => {
		navigate(`/nodes/${id}`)
	}

	const rows = nodes.map((node: any) => {
		return {
			key: node.id,
			name: node.name,
			isActive: node.deleted ? 'No' : 'Yes',
			more: node.id,
			onClickCallback: () => handleClick(node.id),
			...node,
		};
	});

	return <Paper sx={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
		<TableContainer sx={{ overflowX: 'scroll', height: '90%', overflowY: 'hidden' }}>
			<Table
				sx={{
					borderCollapse: 'collapse',
					overflowY: 'hidden',
				}}
			>
				<TableHead>
					<TableRow
						key="header"
						sx={{
							'& th': {
								color: theme.palette.primary.contrastText,
								backgroundColor: theme.palette.primary.main,
							},
						}}
					>
						{columns.map((column: any) => (
							<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
								{column.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
						return (
							<TableRow
								onClick={row.onClickCallback ? row.onClickCallback : undefined}
								hover
								tabIndex={-1}
								key={row.id}
							>
								{columns.map((column: any) => {
									const value = row[column.id];
									return (
										<TableCell key={column.id} align={column.align}>
											{column?.format ? column.format(value) : value}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
		<TablePagination
			component="div"
			rowsPerPageOptions={[15, 25, 100]}
			count={rows.length}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
		/>
	</Paper>
}
