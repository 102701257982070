import React from 'react';
import { ValueComponent } from './ValueComponent';
import { KeyComponent } from './KeyComponent';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';

interface KVPComponentProps {
	kvp: CardFilterKVP;
	kvpIndex: number;
	options: string[];
	onChange: (kvp: CardFilterKVP, index: number) => void;
}

export interface CardFilterKVP {
	key: string;
	value: string;
}

export const KVPComponent = (props: KVPComponentProps): React.ReactElement => {
	const { kvp, kvpIndex, onChange, options } = props;
	const theme = useTheme();
	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	const handleValueChange = (value: string): void => {
		onChange({ ...kvp, value: value }, kvpIndex);
	};

	const handleKeyChange = (value: string): void => {
		onChange({ ...kvp, key: value }, kvpIndex);
	};

	return (
		<>
			{standardScreen && (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexBasis: '90%',
					}}
				>
					<KeyComponent value={kvp.key} onChange={handleKeyChange} options={options} />
					<Typography sx={{ padding: '0px 10px' }}>=</Typography>
					<ValueComponent value={kvp.value} onChange={handleValueChange} />
				</Box>
			)}
			{!standardScreen && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						flexBasis: '90%',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '4px' }}>
						<KeyComponent value={kvp.key} onChange={handleKeyChange} options={options} sx={{ flexBasis: '100%' }} />
						<Typography sx={{ padding: '0px 10px', flexBasis: '10%' }}>=</Typography>
					</Box>
					<ValueComponent value={kvp.value} onChange={handleValueChange} />
				</Box>
			)}
		</>
	);
};
