import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
	id: string;
	firstName: string;
	lastName: string;
	name: string;
	role: number;
	defaultDashboardId: string;
	provisioned: boolean;
	picture?: any;
	icon?: Blob;
}

const initialUserState: UserState = {
	id: '',
	firstName: '',
	lastName: '',
	role: 0,
	name: '',
	defaultDashboardId: '',
	provisioned: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState: initialUserState,
	reducers: {
		update: (state, action: PayloadAction<UserState>) => {
			return { ...state, ...action.payload };
		},
		logout: (_state) => initialUserState,
	},
});
