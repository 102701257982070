import { Box, Button, FormControl, FormLabel, TextField } from '@mui/material';
import { HandleResponseWithSnack, useSubmit } from '../../../Shared/hooks/useSubmit';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { useEffect, useState } from 'react';
import { getFlagValue } from '../utils';
import { ResponseHandler } from '../../../Shared/components';

export const MaxCardSizeSettings = () => {

	const request = useFetch('/admin/settings');

	const [ width, setWidth ] = useState('');
	const [ height, setHeight ] = useState('');

	const data = {
		max_width: width,
		max_height: height
	}

	const { callAPI, state, resetState } = useSubmit('/admin/settings', data);

	const onClick = () => {
		callAPI();
	}

	useEffect(() => {
		if (!request.loading && request.success) {
			setWidth(getFlagValue(request.data, 'max_width') || '1800')
			setHeight(getFlagValue(request.data, 'max_height') || '1800')
		}
	}, [request.loading, request.success, request.data]);

	return <Box sx={{margin: '20px'}}>
		<ResponseHandler request={request}>
			<div>Max Card Resolution</div>
			<FormControl>
				<FormLabel>Max Width</FormLabel>
				<TextField value={width} onChange={(e) => setWidth(e.target.value)} />
				<FormLabel>Max Height</FormLabel>
				<TextField value={height} onChange={(e) => setHeight(e.target.value)} />
				<Button onClick={onClick} sx={{ margin: '10px' }} color="success" variant="contained">Save</Button>
			</FormControl>
			<HandleResponseWithSnack state={state} resetState={resetState} />
		</ResponseHandler>
	</Box>
}