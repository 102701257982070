//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//Internal
import { CARD_INSTANCE_DELETE_URL } from '../../Shared/constants/routes';
import { NodeDialog } from '../../Shared/components';
import { DialogButton } from '../../Shared/components/DialogButton';

export const DeleteCardInstance = (props: any): ReactElement => {
	const { reRenderComponent, id } = props;
	const theme = useTheme();

	const cardInstance = {
		id: id,
	};

	return (
		<NodeDialog
			ButtonComponent={DialogButton}
			url={CARD_INSTANCE_DELETE_URL}
			node={cardInstance}
			dialogText={'Delete'}
			dialogTitle={'Remove Chart from Dashboard'}
			reRenderComponent={reRenderComponent}
			buttonColor={'primary'}
			variant={'text'}
			dialogButtonText={
				<CloseIcon
					sx={{ alignSelf: 'flex-start', color: theme.palette.error.main, opacity: '.05', '&:hover': { opacity: '1' } }}
				/>
			}
		>
			<DialogContentText>{`Are you sure you want to remove this chart?`}</DialogContentText>
		</NodeDialog>
	);
};
