//Node Module
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';

//Internal
import { ENTITIES_RESTORE_URL } from '../../Shared/constants/routes';
import { NodeDialog, NodeIconButton } from '../../Shared/components';
import { RestoreEntityProps } from '../interfaces';
import { NodeBase } from '../../Shared/interfaces';

export const RestoreEntity = (props: RestoreEntityProps): ReactElement => {
	const { reRenderComponent, name, id } = props;

	const entity: NodeBase = {
		name: name,
		id: id,
	};

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={ENTITIES_RESTORE_URL}
			node={entity}
			dialogText={'Restore'}
			dialogTitle={'Restore Entity'}
			reRenderComponent={reRenderComponent}
			Icon={RestoreIcon}
		>
			<DialogContentText>{`Are you sure you want to restore entity ${name}?`}</DialogContentText>
		</NodeDialog>
	);
};
