//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import { ROLES } from '../constants/roles';

export const CreateNodeButton = (props: { setOpen: Function; disabled: boolean }): ReactElement => {
	const { setOpen, disabled } = props;
	const user = useSelector((state: StoreState) => state.user);
	const userIsAdmin = user.role === ROLES.ADMIN;

	return userIsAdmin ? (
		<Button
			onClick={() => setOpen(true)}
			sx={{
				minWidth: 'unset',
				height: '25px',
				maxWidth: '25px',
				padding: 0,
				borderRadius: '50%',
				border: '1px solid #00000023',
			}}
			color="primary"
			aria-label="edit"
			disabled={disabled}
		>
			<AddIcon />
		</Button>
	) : (
		<></>
	);
};
