//Node Modules
import React, { ChangeEvent, ReactElement } from 'react';

//Material UI
import { Button, Box, TextField, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';

const FilterValues = (props: { initValues: string[]; onChange: Function }): ReactElement => {
	const { initValues, onChange } = props;

	const onClick = (): void => {
		onChange([...initValues, '']);
	};

	const onValueChange = (newValue: string, index: number): void => {
		const newValues = [...initValues];
		newValues.splice(index, 1, newValue);
		onChange(newValues);
	};

	const onDelete = (index: number): void => {
		const newValues = [...initValues];
		newValues.splice(index, 1);
		onChange(newValues);
	};

	return (
		<Box sx={{ marginLeft: '20px', marginTop: '10px' }}>
			{initValues.map((value, index) => {
				return (
					<Box key={value + index}>
						<Typography sx={{ fontSize: '12px' }}>Value {index + 1}</Typography>
						<Box sx={{ display: 'flex' }}>
							<TextField
								sx={{ flexBasis: '80%' }}
								autoFocus={index >= 0 || value.length > 0}
								onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
									onValueChange(event.target.value, index)
								}
								value={value}
							/>
							<Button onClick={() => onDelete(index)} sx={{ margin: '3px' }} color="primary" aria-label="edit">
								<Close />
							</Button>
						</Box>
					</Box>
				);
			})}
			<Button onClick={onClick}>+ Add Value</Button>
		</Box>
	);
};

export default FilterValues;
