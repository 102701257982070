import * as yup from 'yup';

export const createCardValidation = yup.object().shape({
	name: yup.string().min(3).trim().required(),
	embedId: yup.string().min(3).trim().required(),
	filterFields: yup.array(),
	identifierField: yup.string().optional(),
});

export const editCardValidation = yup.object().shape({
	name: yup.string().min(3).trim().required(),
	filterFields: yup.array(),
	identifierField: yup.string().optional(),
});
