//Node Modules
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

//Material UI
import { Box, Divider, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { StoreState } from '../../../../store/store';
import StarIcon from '@mui/icons-material/Star';
import { UserState } from '../../../../store/userReducer';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';

//Internal
import { iconWidth, mainColor, selectedColor } from './styles';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}));

export const DashboardItem = (props: { route: any; RouteIcon: any }): ReactElement => {
	const { route, RouteIcon } = props;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const location = useLocation();
	const user: UserState = useSelector((state: StoreState) => state.user, shallowEqual);

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Link
			key={route.name}
			style={{
				textDecoration: 'none',
				position: 'relative',
				color: location.pathname === route.route ? selectedColor : mainColor,
			}}
			to={route.route}
		>
			<Box>
				<ListItemButton
					key={route.name}
					sx={{
						padding: '8px 16px 8px 22px',
						height: '44px',
						background: location.pathname === route.route ? '#E6F4FF' : 'inherit',
						borderRight: location.pathname === route.route ? '2px solid #1677FF' : 'none',
						'&:hover': {
							background: '#E6F4FF',
						},
						'&::after': {
							content: '""',
							position: 'absolute',
							bottom: 0,
							right: 0,
							height: '100%',
							width: '5px',
							zIndex: 100,
						},
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<ListItemIcon
							sx={{
								minWidth: 'unset',
								marginRight: '10px',
							}}
						>
							{user.defaultDashboardId !== route.id ? (
								<RouteIcon
									sx={{
										width: iconWidth,
										color: location.pathname === route.route ? selectedColor : mainColor,
									}}
								/>
							) : (
								<StarIcon
									sx={{
										paddingLeft: '3px',
										width: iconWidth,
										color:
											location.pathname === route.route ||
											(location.pathname === '/' && route.route.includes(user.defaultDashboardId))
												? selectedColor
												: mainColor,
									}}
								/>
							)}
						</ListItemIcon>
						<ListItemText
							disableTypography={true}
							sx={{
								'&.MuiListItemText-root': {
									fontSize: '14px',
									color: location.pathname === route.route ? selectedColor : mainColor,
								},
							}}
							color={location.pathname === route.route ? mainColor : selectedColor}
							primary={route.name}
						/>
					</Box>
					<Box>
						<StyledMenu
							id="demo-customized-menu"
							MenuListProps={{
								'aria-labelledby': 'demo-customized-button',
							}}
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
						>
							<MenuItem onClick={handleClose} disableRipple>
								<StarIcon />
								Set as Default
							</MenuItem>
							<MenuItem onClick={handleClose} disableRipple>
								<EditIcon />
								Rename
							</MenuItem>

							<MenuItem onClick={handleClose} disableRipple>
								<FileCopyIcon />
								Duplicate
							</MenuItem>
							<Divider sx={{ my: 0.5 }} />

							<MenuItem onClick={handleClose} disableRipple>
								<DeleteIcon />
								Delete
							</MenuItem>
						</StyledMenu>
					</Box>
				</ListItemButton>
			</Box>
		</Link>
	);
};
